import { trpc } from '../../../../App'

export function useUpdateAkteMutation() {
  const trpcCtx = trpc.useUtils()
  return trpc.akte.update.useMutation({
    onSuccess: ({ id }) => {
      trpcCtx.akte.byId.invalidate({ id })
      trpcCtx.akte.getAll.invalidate()
      trpcCtx.audit.invalidate({ entryId: id, entryType: 'akte' })
    },
  })
}
