import { useMemo } from 'react'

export function objDeltaEntries<A extends { [key: string]: unknown }>(
  a: A | undefined,
  b: any
): Array<[keyof A, boolean]> {
  const bObj = b ?? {}
  return Object.entries(a ?? {}).map(([key, value]) =>
    [
      key,
      value instanceof Date
        ? value.getTime() != bObj[key]?.getTime()
        : bObj[key] != value
    ]
  )
}

export function objDelta<A extends { [key: string]: unknown }>(
  a: A | undefined,
  b: any
): { [key in keyof A]?: boolean } {
  return Object.fromEntries(objDeltaEntries(a, b)) as { [key in keyof A]?: boolean }
}

export function useObjDelta<A extends { [key: string]: unknown }>(a: A | undefined, b: any) {
  return useMemo(() => objDelta(a, b), [a, b])
}
