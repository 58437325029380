import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  z,
  ZodRawShape,
  ZodDate,
  ZodNumber,
  ZodString,
  ZodTypeAny,
  ZodError,
  ZodOptional,
  ZodBoolean,
  ZodEnum,
  ZodNullable,
} from 'zod'

import {
  FormLabelKeys,
  FormNotesKeys,
  CanonicalFormLabels,
  canonicalFormNotes,
  CanonicalFormNotes,
  addPrefix,
  asFormLabel,
} from '../../locales/translationKeys'

import { View } from 'react-native'
import { InputBoolean, InputDate, InputNumber, InputText } from './InputFields'
import { dsv } from '../../styles/defaults'
import { Text } from '../TextComponents'
import { InputEnum } from './InputFields/InputEnum'
import { UserDropdownSelection } from './InputFields/UserDrpdownSelection'
import { Div } from '@expo/html-elements'
import { mitarbeiterDropdownFields } from './mitarbeiterDropdownFields'

export function calculateTranslationKeys<TLabel extends string>(fieldKey: TLabel) {
  return {
    labelTranslationKey: addPrefix('labels', fieldKey),
    notesTranslationKey:
      (canonicalFormNotes as string[]).indexOf(fieldKey) != -1
        ? addPrefix('notes', fieldKey as CanonicalFormNotes)
        : undefined,
  }
}

export function autoFormMeta<TLabel extends CanonicalFormLabels>(fieldKey: TLabel) {
  const transKeys = calculateTranslationKeys(fieldKey)
  return formMeta(transKeys.labelTranslationKey, transKeys.notesTranslationKey)
}

export function formMeta(labelTranslationKey: FormLabelKeys, notesTranslationKey?: FormNotesKeys) {
  return { description: JSON.stringify({ labelTranslationKey, notesTranslationKey }) }
}

function getFormMetaData(fieldType: z.ZodTypeAny) {
  const realFieldType = fieldType instanceof ZodOptional ? fieldType.unwrap() : fieldType
  if (realFieldType.description == undefined) {
    return undefined
  }
  return JSON.parse(realFieldType.description) as {
    labelTranslationKey: FormLabelKeys
    notesTranslationKey?: FormNotesKeys
  }
}

type FormFieldPath = {
  fieldPath?: string[]
  fieldName: string
}

type ZodFormFieldParameters<T extends ZodTypeAny = ZodTypeAny> = {
  fieldSchema: T
  value: z.infer<T> | undefined
  setValue?: (value: undefined | z.infer<T>) => void
  showValidationErrors: boolean

  onBlur?: () => void
  isMandatory?: boolean
} & FormFieldPath

function zodFormFieldID({ fieldPath, fieldName }: FormFieldPath) {
  return (fieldPath?.join('_').concat('_') ?? '') + fieldName
}

function formatZodErrors(e: ZodError) {
  return e.errors.map((error) => error.message)
}

export function zodValidate<T extends ZodTypeAny = ZodTypeAny>(
  zodType: T,
  value: unknown
): { errors: undefined; valideValue: z.infer<T> } | { errors: string[]; valideValue: undefined } {
  try {
    const valideValue = zodType.parse(value)
    return { errors: undefined, valideValue }
  } catch (e) {
    if (e instanceof ZodError) {
      return { errors: formatZodErrors(e), valideValue: undefined }
    } else {
      throw e
    }
  }
}

function getZodValue<T extends ZodTypeAny = ZodTypeAny>(
  zodType: T,
  value: unknown
): undefined | ReturnType<T['parse']> {
  try {
    return zodType.parse(value) // TODO: empty string as undefined ?
  } catch (e) {
    if (e instanceof ZodError) {
      return undefined
    } else {
      throw e
    }
  }
}

type ZodFormFieldInputParameters<T extends ZodTypeAny> = {
  fieldId: string
  fieldSchema: T
  strValue: string
  setStrValue?: (value: string) => void
  handleOnBlur: (valueOverwrite?: string) => void
  hasErrors?: boolean
} & FormFieldPath

function ZodFormFieldInput<T extends ZodTypeAny>({
  fieldSchema,
  strValue,
  setStrValue,
  fieldName,
  fieldPath,
  fieldId,
  handleOnBlur,
  hasErrors,
}: ZodFormFieldInputParameters<T>) {
  if (fieldSchema instanceof ZodOptional) {
    return ZodFormFieldInput({
      fieldSchema: fieldSchema.unwrap(),
      strValue,
      setStrValue,
      fieldId,
      fieldName,
      fieldPath,
      handleOnBlur,
      hasErrors,
    })
  }
  if (fieldSchema instanceof ZodNullable) {
    return ZodFormFieldInput({
      fieldSchema: fieldSchema.unwrap(),
      strValue,
      setStrValue,
      fieldId,
      fieldName,
      fieldPath,
      handleOnBlur,
      hasErrors,
    })
  }
  //if (fieldSchema instanceof ZodEffects) {
  //  return ZodFormFieldInput({
  //    fieldSchema: fieldSchema.innerType(),
  //    strValue,
  //    setStrValue,
  //    fieldId,
  //    fieldName,
  //    fieldPath,
  //    handleOnBlur,
  //  })
  //}
  const userRole = mitarbeiterDropdownFields[fieldName]
  if (userRole !== undefined) {
    // TODO: cleanup
    return (
      <UserDropdownSelection
        fieldId={fieldId}
        strValue={strValue}
        setStrValue={setStrValue}
        onBlur={handleOnBlur}
        roles={userRole}
      />
    )
  }

  if (fieldSchema instanceof ZodString) {
    return <InputText fieldId={fieldId} strValue={strValue} onBlur={handleOnBlur} setStrValue={setStrValue} />
  }
  if (fieldSchema instanceof ZodNumber) {
    return <InputNumber fieldId={fieldId} strValue={strValue} onBlur={handleOnBlur} setStrValue={setStrValue} />
  }

  if (fieldSchema instanceof ZodDate) {
    return <InputDate fieldId={fieldId} strValue={strValue} onBlur={handleOnBlur} setStrValue={setStrValue} />
  }
  if (fieldSchema instanceof ZodBoolean) {
    return <InputBoolean fieldId={fieldId} strValue={strValue} onBlur={handleOnBlur} setStrValue={setStrValue} />
  }
  if (fieldSchema instanceof ZodEnum) {
    const options = ['', ...Object.values(fieldSchema.Enum)]
    return (
      <InputEnum
        fieldId={fieldId}
        strValue={strValue}
        setStrValue={setStrValue}
        onBlur={handleOnBlur}
        options={options}
      />
    )
  }

  throw new Error('unsupported Field Input Type')
}

function ZodFormValueToString<T extends ZodTypeAny>(fieldSchema: T, value: z.infer<T> | undefined) {
  if (fieldSchema instanceof ZodOptional) {
    return ZodFormValueToString(fieldSchema.unwrap(), value)
  }
  if (fieldSchema instanceof ZodNullable) {
    return ZodFormValueToString(fieldSchema.unwrap(), value)
  }

  if (value == undefined) return ''

  // TODO: bool2string support
  if (fieldSchema instanceof ZodBoolean) {
    return `${value}`
  }

  if (fieldSchema instanceof ZodString) {
    return value
  }
  if (fieldSchema instanceof ZodNumber) {
    return String(value)
  }
  if (fieldSchema instanceof ZodDate) {
    return value.toISOString().substring(0, 10)
  }
  if (fieldSchema instanceof ZodEnum) {
    return value // TODO: validate
  }

  throw new Error('unsupported Field Input Type 2String')
}

function ZodFormField<TFieldType extends ZodTypeAny>(
  parameter: ZodFormFieldParameters<TFieldType> & { inputFieldsOnlyNoLable: boolean } // TODO: inputFieldsOnlyNoLable is a workaround, ZodFormField should be refactored (add a second function ZodFormFieldWithLables)
) {
  const [strValue, setStrValue] = useState('')
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  useEffect(() => {
    if (parameter.value != undefined) {
      // TODO: it is no longer possible to reset the form Value to undefined from outside, but this was never used for now
      setStrValue(ZodFormValueToString(parameter.fieldSchema, parameter.value))
    }
  }, [parameter.fieldSchema, parameter.value])
  const handleOnBlur = useCallback(
    (event: FocusEvent<unknown>, valueOverwrite?: string) => {
      setShowValidationErrors(true)
      const valueToSet = valueOverwrite ?? strValue
      parameter.setValue!(valueToSet === '' ? undefined : getZodValue(parameter.fieldSchema, valueToSet))
      if (parameter.onBlur) {
        parameter.onBlur()
      }
    },
    [setShowValidationErrors, parameter, strValue]
  )

  const { t } = useTranslation()

  const fieldMeta = useMemo(() => {
    const assignedMetaData = getFormMetaData(parameter.fieldSchema)
    if (assignedMetaData != undefined) {
      return assignedMetaData
    }
    const label = asFormLabel(parameter.fieldName)
    if (label) {
      return calculateTranslationKeys(label)
    }
    return undefined
  }, [parameter.fieldSchema, parameter.fieldName])

  const { errors } = useMemo(() => {
    if(strValue.length == 0){
      return {errors: true} // hotfix
    }
    return zodValidate(parameter.fieldSchema, strValue)
  }, [strValue, parameter.fieldSchema])
  const fieldId = zodFormFieldID(parameter)

  const setValue = useMemo(() => {
    if (parameter.setValue) {
      return (value: string) => {
        setStrValue(value)
      }
    } else {
      return undefined
    }
  }, [parameter])
  if (parameter.inputFieldsOnlyNoLable) {
    return (
      <ZodFormFieldInput
        fieldSchema={parameter.fieldSchema}
        strValue={strValue}
        setStrValue={setValue}
        handleOnBlur={handleOnBlur}
        fieldId={fieldId}
        fieldName={parameter.fieldName}
        fieldPath={parameter.fieldPath}
        hasErrors={errors != undefined}
      />
    )
  }
  const isUserError = mitarbeiterDropdownFields[parameter.fieldName] != undefined && parameter.value == undefined

  return (
    <>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 12,
          alignItems: 'center',
        }}
      >
        <label
          htmlFor={fieldId}
          style={{
            color: dsv.colors.neutral,
            marginRight: '2rem',
            width: '30%',
            ...dsv.text,
          }}
        >
          <Text>
            {fieldMeta ? t(fieldMeta.labelTranslationKey) : `no label for ${parameter.fieldName}`}
            {parameter.isMandatory && <Text style={{ color: 'red', marginLeft: '.5em' }}>*</Text>}
            {fieldMeta?.notesTranslationKey && (
              <>
                {' '}
                <br />
                {t(fieldMeta.notesTranslationKey)}
              </>
            )}
          </Text>
        </label>
        <View
          style={{
            width: '70%',
            backgroundColor: setValue == undefined ? dsv.colors.connectGrau7 : dsv.colors.white,
          }}
        >
          <Div
            style={
              parameter.isMandatory && (errors != undefined || isUserError)
                ? {
                  borderColor: dsv.colors.BegleitfarbeOrangeDunkel,
                  borderWidth: 2,
                  borderStyle: 'solid',
                  margin: -1,
                  borderRadius: 5,
                }
                : undefined
            }
          >
            <ZodFormFieldInput
              fieldSchema={parameter.fieldSchema}
              strValue={strValue}
              setStrValue={setValue}
              handleOnBlur={handleOnBlur}
              fieldId={fieldId}
              fieldName={parameter.fieldName}
              fieldPath={parameter.fieldPath}
              hasErrors={errors != undefined}
            />
          </Div>
          {false && // Disables error Texts, will probably be removed later
            errors != undefined &&
            (showValidationErrors || parameter.showValidationErrors) && (
            <Text style={{ marginTop: 5, fontSize: 12, color: dsv.colors.error }}>{errors?.join('\n')}</Text>
          )}
        </View>
      </View>
    </>
  )
}

//export function ZodFormFields<T extends {[field in CanonicalFormLabels]: ZodTypeAny}>(
export function ZodFormFields<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  state: Partial<z.infer<ReturnType<typeof z.object<T>>>>,
  setState: React.Dispatch<React.SetStateAction<Partial<z.infer<ReturnType<typeof z.object<T>>>>>>,
  allowedFields: { [key in keyof T]?: boolean },
  showValidationErrors?: true,
  onBlur?: () => void,
  inputFieldsOnlyNoLable = false, // TODO: inputFieldsOnlyNoLable is a workaround, ZodFormFields should be refactored (add a second function ZodFormFieldsWithLables)
  isMandatory?: (
    data: Partial<z.infer<ReturnType<typeof z.object<T>>>>,
    key: keyof z.infer<ReturnType<typeof z.object<T>>>
  ) => boolean
) {
  const shapeEntries = useMemo(() => Object.entries(schema.shape), [schema.shape])

  const setValueFunctions = useMemo(
    () =>
      shapeEntries.map(([name, _]) => {
        if (allowedFields[name]) {
          return (fieldValue: unknown) => setState((oldState) => ({ ...oldState, [name]: fieldValue }))
        }
        return undefined
      }),
    [allowedFields, setState, shapeEntries]
  )

  const zodFieldEntries = shapeEntries.map(([name, fieldType], idx) => {
    return [
      name,
      <ZodFormField
        key={zodFormFieldID({ fieldName: name })}
        fieldName={name}
        fieldSchema={fieldType}
        value={state[name as CanonicalFormLabels]}
        showValidationErrors={showValidationErrors || false}
        setValue={setValueFunctions[idx]}
        onBlur={onBlur}
        inputFieldsOnlyNoLable={inputFieldsOnlyNoLable}
        isMandatory={isMandatory ? isMandatory(state, name) : false}
      />,
    ]
  })
  return zodFieldEntries as [keyof T, JSX.Element][]
}

/**
 * DO NOT USE THIS FUNCTION OUT SIDE OF coerceIfNeeded
 */
function _coerceIfNeeded<T extends ZodTypeAny>(fieldSchema: T): T {
  if (fieldSchema instanceof ZodBoolean) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return z.coerce.boolean() as any as T
  }
  if (fieldSchema instanceof ZodNumber) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return z.coerce.number() as any as T
    // TODO: do not reset sting value for numbers if text in string
  }
  if (fieldSchema instanceof ZodString) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return z.string().nonempty() as any as T // TODO: make nice
  }
  return fieldSchema as T
}

function coerceIfNeeded<T extends ZodTypeAny>(
  fieldSchema: T
): T extends ZodNullable<ZodOptional<infer X>>
  ? ReturnType<typeof _coerceIfNeeded<X>>
  : ReturnType<typeof _coerceIfNeeded<T>> {
  if (fieldSchema instanceof ZodOptional) {
    const inner = fieldSchema.unwrap()
    if (inner instanceof ZodNullable) {
      return _coerceIfNeeded(inner.unwrap()).nullish() as T extends ZodNullable<ZodOptional<infer X>>
        ? ReturnType<typeof _coerceIfNeeded<X>>
        : ReturnType<typeof _coerceIfNeeded<T>>
    }
  }
  return _coerceIfNeeded(fieldSchema) as T extends ZodNullable<ZodOptional<infer X>>
    ? ReturnType<typeof _coerceIfNeeded<X>>
    : ReturnType<typeof _coerceIfNeeded<T>>
}

export function SchemaToFormSchema<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  modifier?: Partial<{ [field in keyof T]: ZodTypeAny }>
) {
  const coercedEntries = Object.entries(schema['shape']).map(([name, typ]) => {
    return [name, coerceIfNeeded(typ)]
  })
  const coercedObj = Object.fromEntries(coercedEntries) as {
    [field in keyof (typeof schema)['shape']]: ReturnType<typeof coerceIfNeeded<(typeof schema)['shape'][field]>>
  }

  const form = z.object({
    ...coercedObj,
    ...modifier,
  })
  return form
}
/*
export const FormData = z.object({
  someText:     z.string().min(5),
  aNumber:      z.coerce.number().min(3).max(10),  // TODO check type coerce for ""
  optionalText: z.string().optional(),  // TODO check type coerce for ""
  aDate:        z.coerce.date()
})
*/
