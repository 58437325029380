import { useCallback, useEffect, useState } from 'react'
import { CalcAkte, CalcAkteSchema } from '@dpa/common/dist'
import { trpc } from '../../App'


export function useAktePages(pageSize: number) {
  const [itemCount, setItemCount] = useState<number | undefined | null>(undefined)
  const [pageIdx, setPageIdx] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const rawData = trpc.akte.getAll.useQuery({ page: { idx: pageIdx, size: pageSize } })

  const [data, setData] = useState<Array<CalcAkte>>()
  useEffect(() => {
    if (isLoading && !rawData.isLoading) {
      const page = rawData.data!
      if (Array.isArray(page)) {
      } else {
        const parsedData = page.data.map((p) => CalcAkteSchema.parse(p))
        setData(data == undefined ? parsedData : [...data, ...parsedData])
        setItemCount(page.itemCount)
      }
      setIsLoading(false)
    }
  }, [rawData.isLoading, rawData.data])

  const loadMore = useCallback(
    () => {
      if (!isLoading) {
        setPageIdx(pageIdx + 1)
        setIsLoading(true)
        return true
      } else {
        return false
      }
    },
    [pageIdx, isLoading]
  )
  return {
    data,
    isLoading,
    pageSize,
    pagesLoaded: pageIdx,
    itemCount,
    loadMore,
  }
}
