
import { Div } from '@expo/html-elements'
import { CardJsxHeader } from '../../../../components/Card'
import { H2 } from '../../../../components/TextComponents'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useTranslation } from 'react-i18next'

import { dsv } from '../../../../styles/defaults'

import { GpjButton } from './GpjButton'
import { useCallback, useMemo, useState } from 'react'
import SelectList from '../../../../components/SelectList'

export function ProjectDecisions() {
  const { t } = useTranslation()

  const akte = useAkteContext()

  const [questionState, setQuestionState] = useState<string | undefined>(undefined)
  const questionOptions = useMemo(
    () => {
      const questions = (akte.availableTransitions ?? [])
        .filter(availableTransition => availableTransition[1].meta?.flowUi == 'question')
        .map((availableQuestion) => ({ key: availableQuestion[0], value: t("btnTxt_" + availableQuestion[0]) }))

      return questions
    }
    , [akte.availableTransitions, t]
  )

  const submitTransition = useCallback(() => {
    setQuestionState(undefined)
    questionState && akte.performTransition!(questionState)
  }, [questionState, akte.performTransition])


  if (akte.isLoading || akte.performTransition == null || questionOptions.length == 0) {
    return <></>
  }

  return (
    <CardJsxHeader zIndex={1}>
      <Div
        style={{
          width: '95%',
          backgroundColor: dsv.colors.BegleitfarbeOrangeDunkel,
          padding: 10,
          marginHorizontal: "auto",
          marginTop: -10,
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: 'row',
        }}
      >
        <H2
          style={{
            marginHorizontal: '16px',
            color: dsv.colors.connectGrau,
            whiteSpace: "nowrap",
          }}
        >
          Ausstehende Entscheidung:
        </H2>
        <Div style={{
          flexGrow: "1",
          width: 'auto',
          display: "flex",
          alignItems: "center",
          flexDirection: 'row',
          justifyContent: "space-between",
        }}>
          <Div style={{
            flexGrow: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: 'row',
          }}>
            <SelectList
              key={questionOptions[0].key}
              disabled={akte.performTransitionLoading}
              data={questionOptions}
              setSelected={setQuestionState}
              dropdownStyles={{
                position: 'absolute',
                top: 8,
                marginTop: 33,
                zIndex: 98,
              }}
              boxStyles={{
                height: 36,
                backgroundColor: dsv.colors.connectGrau7,
                borderRadius: 4,
                borderStyle: 'solid',
                borderColor: dsv.colors.connectGrau30,
                position: 'relative',
                zIndex: 98,
              }}
              inputStyles={{
                height: 36,
                minWidth: 400,
                backgroundColor: dsv.colors.connectGrau7,
                borderColor: dsv.colors.connectGrau30,
                fontSize: 16,
                display: 'flex',
                alignItems: "center",

              }}
              dropdownItemStyles={{ position: 'relative', zIndex: 98 }}
              dropdownTextStyles={{ position: 'relative', zIndex: 98 }}
              placeholder="Auswahl tätigen"
            />
            <GpjButton
              text="Bestätigen"
              onPress={submitTransition}
              enabledBackground={dsv.colors.BegleitfarbeOrange}
              isDisabled={akte.performTransitionLoading || !questionState}
            />
          </Div>
        </Div>
      </Div>
    </CardJsxHeader>
  )
}
