import { TBody, Table, THead, TR, Div } from '@expo/html-elements'
import { H4, TD, Text, TH } from '../../../../components/TextComponents'

import { dsv } from '../../../../styles/defaults'

import { SubCard } from '../../../../components/SubCard'
import type { Invoice } from '@dpa/server/integations/bc/InvoiceSchema'
import { toCurrencyString } from '../../../../utils/currency'
import { useMemo } from 'react'

interface ProductsCardProps {
  title: string
  invoice: Invoice
}

export function ProductsCard({ title, invoice }: ProductsCardProps) {

  const zwischensumme = useMemo(() => {
    return invoice.lines.reduce(
      (carry, line) => {
        return carry + (line.lineamount ?? 0)
      }, 0 as number)
  }, 
  [invoice]
  )

  return (
    <SubCard width="100%" title={title}>
      <Table
        style={{
          // TODO: Fix styling for mobile app
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          borderSpacing: '0px 6px',
        }}
      >
        <THead style={{ backgroundColor: dsv.colors.GpJouleGelb }}>
          <TR>
            <TH style={{ padding: 10, textAlign: 'left' }}>Artikelnummer</TH>
            <TH style={{ padding: 10, textAlign: 'left' }}>Artikelbezeichnung</TH>
            <TH style={{ padding: 10, textAlign: 'center' }}>Menge</TH>
            {/*<TH style={{ padding: 10, textAlign: 'center' }}>Stückpreis</TH>
                            <TH style={{ padding: 10, textAlign: 'center' }}>Rabatt</TH>
                            //TODO: Rechnung stylen und anpassen
                            */}
            <TH style={{ padding: 10, textAlign: 'center' }}>Einzelpreis</TH>
            <TH style={{ padding: 10, textAlign: 'center' }}>Gesamtpreis</TH>
          </TR>
        </THead>
        <TBody>
          {invoice.lines.map((line) => (
            <>
              { line.quantity != 0 &&  <TR key={`${line.lineno_}_spcaer`} >
                <TD style={{ height: 5 }}></TD>
              </TR>
              }
              <TR key={line.lineno_}>
                <TD style={{ paddingLeft: 10 }}>{line.no_}</TD>
                <TD>{line.description}</TD>
                <TD style={{ textAlign: 'center' }}>
                  {line.quantity == 0 ? (
                    ''
                  ) : (
                    <>
                      {line.quantity}&nbsp;{line.unitofmeasure}
                    </>
                  )}
                </TD>
                {/*<TD style={{ textAlign: 'right' }}>
                                {line.quantity == 0 ? '' : toCurrencyString(line.lineamount ?? 0)}
                              </TD>
                              <TD style={{ textAlign: 'right' }}>{line.quantity == 0 ? '' : '0%'}</TD>*/}
                <TD style={{ textAlign: 'right', paddingRight: 10 }}>
                  {line.quantity == 0 ? '' : toCurrencyString(line.unitprice ?? 0)}
                </TD>
                <TD style={{ textAlign: 'right', paddingRight: 10 }}>
                  {line.quantity == 0 ? '' : toCurrencyString(line.lineamount ?? 0)}
                </TD>
              </TR>
            </>
          ))}
          <TR>
            <TD style={{ height: 5 }}></TD>
          </TR>
        </TBody>
      </Table>
      <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
      <Div style={{ marginLeft: 'auto' }}>
        <Table style={{ borderSpacing: '10px 5px' }}>
          <TR>
            <TD>
              <Text>Zwischensumme</Text>
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <Text>
                {toCurrencyString(
                  invoice.lines.reduce((carry, line) => {
                    return carry + (line.lineamount ?? 0)
                  }, 0 as number)
                )}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD></TD>
            <TD style={{height: 1, backgroundColor: dsv.colors.connectGrau }}></TD>
          </TR>
          <TR>
            <TD>
              <Text>Rechnungsrab.-Wert</Text>
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <Text>
                {toCurrencyString(
                  (invoice.header.amount ?? 0) - zwischensumme
                )}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={{ marginTop: 5 }}>Rabattierter Betrag</Text>
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <Text>
                {toCurrencyString(
                  invoice.header.amount ?? 0
                )}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={{ marginTop: 5 }}>USt.-Betrag</Text> 
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <Text>
                {toCurrencyString(
                  (invoice.header.amountincludingvat ?? 0) - (invoice.header.amount ?? 0)
                )}
              </Text>
            </TD>
          </TR>
          <TR style={{}}>
            <TD>
              <H4 style={{ marginTop: 5 }}>Gesamt</H4>
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <H4>
                {toCurrencyString(
                  invoice.header.amountincludingvat ?? 0
                )}
              </H4>
            </TD>
          </TR>
        </Table>
        <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
        <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
      </Div>
    </SubCard>
  )
}
