import { View } from 'react-native'

import { Section, Div } from '@expo/html-elements'
import { Card, CardBody } from '../../../../components/Card'
import { H2 } from '../../../../components/TextComponents'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { LoadingScreen } from '../../../../components/LoadingScreen'

import { dsv } from '../../../../styles/defaults'
import { useAllPermissions } from '../../../../hooks/useAllPermissions'
import { useUserListContext } from '../../../../hooks/context/useUserListContext'
import { useProcessGateContext } from '../../../../hooks/context/useProcessGateContext'

import { useAkteSalesLines } from '../../../../hooks/useAkteSalesLines'
import { ProjectCardHeader } from '../components/ProjectCardHeader'
import { ProductsCard } from './ProductsCard'
import { useMemo } from 'react'
import { useAkteInvoices } from '../../../../hooks/useAkteInvoices'
import { useAkteServiceContractLines } from '../../../../hooks/useAkteServiceContractLines'
import { ServiceCard } from './ServiceCard'

export function ProjectProducts() {
  const akte = useAkteContext()
  const { isLoading: usersIsLoading } = useUserListContext()

  const salesLines = useAkteSalesLines(akte.identifier)
  const invoices = useAkteInvoices(akte.identifier)
  const contractLines = useAkteServiceContractLines(akte.identifier)
  
  const allPermissions = useAllPermissions()

  const processGateCtx = useProcessGateContext()

  const salesLinesPerType = useMemo(
    () => ({
      PDB_Auftragsnummer: 
        invoices.data?.find((invoice) => invoice.header.orderType == 'PDB_Auftragsnummer'),
      PDB_Auftragsnummer_PC: 
        invoices.data?.find((invoice) => invoice.header.orderType == 'PDB_Auftragsnummer_PC'),
      PDB_Auftragsnummer_Nachtrag0:
        invoices.data?.find((invoice) => invoice.header.orderType == 'PDB_Auftragsnummer_Nachtrag0'),
      PDB_Auftragsnummer_Nachtrag1:
        invoices.data?.find((invoice) => invoice.header.orderType == 'PDB_Auftragsnummer_Nachtrag1'),
      PDB_Auftragsnummer_Nachtrag2:
        invoices.data?.find((invoice) => invoice.header.orderType == 'PDB_Auftragsnummer_Nachtrag2'),
      PDB_Auftragsnummer_Nachtrag3:
        invoices.data?.find((invoice) => invoice.header.orderType == 'PDB_Auftragsnummer_Nachtrag3'),
      PDB_Auftragsnummer_Nachtrag4:
        invoices.data?.find((invoice) => invoice.header.orderType == 'PDB_Auftragsnummer_Nachtrag4'),
    }),
    [invoices.data]
  )

  const isLoading =
    invoices.isLoading || 
    akte.isLoading || 
    allPermissions.isLoading || 
    processGateCtx.isLoading || 
    salesLines.isLoading || 
    usersIsLoading || 
    contractLines.isLoading

  return (
    <Section style={{ flex: 1 }}>
      <Card customStyle={{ flex: 1 }}>
        {isLoading && (
          <CardBody customStyle={{ minHeight: 300 }}>
            <LoadingScreen active />
          </CardBody>
        )}
        {!isLoading && (
          <>
            <ProjectCardHeader />
            <CardBody customStyle={{ flex: 1, overflow: 'scroll' }}>
              <View>
                <View
                  style={{
                    paddingTop: 10,
                    width: '100%',
                    maxWidth: 1480,
                    margin: 'auto',
                  }}
                >
                  {akte.serverState.PDB_Projektnummer == undefined ? (
                    <Div style={{ height: 100, backgroundColor: dsv.colors.connectGrau7 }}>
                      <H2
                        style={{
                          margin: 'auto',
                          color: dsv.colors.black,
                        }}
                      >
                        Produkte aus BC, bitte Projektnummer und Auftragsnummer hinterlegen
                      </H2>
                    </Div>
                  ) : (
                    <>
                      {salesLinesPerType.PDB_Auftragsnummer != undefined && (
                        <View style={{ marginTop: 10 }}>
                          <ProductsCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer} - Einmalkosten`}
                            invoice={salesLinesPerType.PDB_Auftragsnummer}
                          />
                        </View>
                      )}
                      {salesLinesPerType.PDB_Auftragsnummer_PC != undefined && (
                        <View style={{ marginTop: 10 }}>
                          <ProductsCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer_PC} - Plan & Charge`}
                            invoice={salesLinesPerType.PDB_Auftragsnummer_PC}
                          />
                        </View>
                      )}
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag0 != undefined && (
                        <View style={{ marginTop: 10 }}>
                          <ProductsCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag0} - Nachtrag I`}
                            invoice={salesLinesPerType.PDB_Auftragsnummer_Nachtrag0}
                          />
                        </View>
                      )}
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag1 != undefined && (
                        <View style={{ marginTop: 10 }}>
                          <ProductsCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag1} - Nachtrag II`}
                            invoice={salesLinesPerType.PDB_Auftragsnummer_Nachtrag1}
                          />
                        </View>
                      )}
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag2 != undefined && (
                        <View style={{ marginTop: 10 }}>
                          <ProductsCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag2} - Nachtrag III`}
                            invoice={salesLinesPerType.PDB_Auftragsnummer_Nachtrag2}
                          />
                        </View>
                      )}
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag3 != undefined && (
                        <View style={{ marginTop: 10 }}>
                          <ProductsCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag3} - Nachtrag IV`}
                            invoice={salesLinesPerType.PDB_Auftragsnummer_Nachtrag3}
                          />
                        </View>
                      )}
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag4 != undefined && (
                        <View style={{ marginTop: 10 }}>
                          <ProductsCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag4} - Nachtrag V`}
                            invoice={salesLinesPerType.PDB_Auftragsnummer_Nachtrag4}
                          />
                        </View>
                      )}
                      {contractLines.data != undefined && contractLines.data.length!=0 && (
                        <View style={{ marginTop: 10 }}>
                          <ServiceCard
                            title={`Produkte aus ${akte.serverState.PDB_Auftragsnummer_laufende_Kosten} - laufende Kosten`}
                            sericeLines={contractLines.data}
                          />
                        </View>
                      )}
                    </>
                  )}
                </View>
              </View>
            </CardBody>
          </>
        )}
      </Card>
    </Section>
  )
}
