import { ident_dienstwagen_ladekartenBestellungEnumSchema, ident_mieter_wieEnumSchema } from '@dpa/common/dist'
import type { CalcAkte } from '@dpa/common/dist/calculate/akte'
import type { DisplayConditions } from '@dpa/common/dist/process/process'

export const akteDisplayConditions: DisplayConditions<CalcAkte> = {
  LisInhaber: {
    description: 'Lis inhaber nur anzeigen wenn abweichend rechnungsanschrift',
    condition: (akte) => akte.PDB_LIS_Inhaber_abweichend_rechnungsanschrift != 'Ja',
    patch: { PDB_LIS_Inhaber: 'hidden' },
  },
  LisBetreiber: {
    description: 'Lis beteriber nur anzeigen wenn abweichend Connect',
    condition: (akte) => akte.PDB_LIS_Betreiber_abweichend_connect != 'Ja',
    patch: { PDB_LIS_Betreiber: 'hidden' },
  },
  pdbGrundstueckseigentuemer: {
    description: 'Grundstückseigentümer nur anzeigen wenn abweichend Debitor',
    condition: (akte) => akte.PDB_Grundstueckseigentuemer_abweichend_LIS != 'Ja',
    patch: {
      PDB_Grundstueckseigentuemer_Name: 'hidden',
      PDB_Grundstueckseigentuemer_EMail: 'hidden',
      PDB_Grundstueckseigentuemer_Telefon: 'hidden',
      PDB_Grundstueckseigentuemer_Adresse: 'hidden',
      PDB_Wartung_Gestattungsvertrag: 'hidden',
    },
  },
  pdbAnschlussnutzer: {
    description: 'Anschlussnutzer nur anzeigen wenn abweichend Debitor',
    condition: (akte) => akte.PDB_Anschlussnutzer_abweichend_LIS != 'Ja',
    patch: {
      PDB_Anschlussnutzer_Name: 'hidden',
      PDB_Anschlussnutzer_EMail: 'hidden',
      PDB_Anschlussnutzer_Telefon: 'hidden',
      PDB_Anschlussnutzer_Adresse: 'hidden',
    },
  },
  lisStandort: {
    description: 'Standort nur anzeigen wenn abweichend rechnung',
    condition: (akte) => akte.UE_Kundendaten_LIS_Standort_abw_rechnung != 'Ja',
    patch: {
      UE_Kundendaten_LIS_Standort_Strasse: 'hidden',
      UE_Kundendaten_LIS_Standort_PLZ: 'hidden',
      UE_Kundendaten_LIS_Standort_Stadt: 'hidden',
    },
  },
  firmenlogo: {
    description: 'Wenn Firmenlogo auf display? = nein',
    condition: (akte) => akte.PDB_FirmenlogoAufDisplay != 'Ja',
    patch: {
      PDB_FirmenlogoLink: 'hidden',
    },
  },
  foerderung: {
    description: 'Wenn Förderung vorhanden? = nein',
    condition: (akte) => akte.Foerderung_vorhanden != 'Ja',
    patch: {
      Foerderung: 'hidden',
      FoerderungEnde: 'hidden',
      FoerderungClearing: 'hidden',
    },
  },
  wartungsvertrag: {
    description: 'Wenn Service-/Wartungsvertrag benötigt? = nein',
    condition: (akte) => akte.PDB_Backend_Servicevertrag != 'Ja',
    patch: {
      PDB_Backend_ServicevertragVersandt: 'hidden',
      PDB_Backend_OtrisLink: 'hidden',
    },
  },
  kartenterminal: {
    description: 'Wenn Kartenterminal? = nein',
    condition: (akte) => akte.PDB_Backend_Kartenterminal != 'Ja',
    patch: {
      PDB_Backend_Vorautorisierungsbetrag: 'hidden',
      PDB_Backend_Zeittarif_AC: 'hidden',
      PDB_Backend_Zeittarif_DC: 'hidden',
      PDB_Backend_Waehrung: 'hidden',
      PDB_Backend_Steuersatz: 'hidden',
      PDB_Backend_SteuernummerDesKunden: 'hidden',
      PDB_Backend_FirmennameDesKunden: 'hidden',
      PDB_Backend_Firmenanschrift: 'hidden',
      PDB_Backend_EODTime: 'hidden',
    },
  },
  akrtenterminal: {
    description: 'Wenn Land Deutschland',
    condition: (akte) => akte.BC_Rechnungsanschrift_Land == 'DE',
    patch: {
      PDB_Backend_Waehrung: 'hidden',
      PDB_Backend_Steuersatz: 'hidden',
      PDB_Backend_SteuernummerDesKunden: 'hidden',
    },
  },
  Smart1Controller: {
    description: 'Wenn Smart1-Controller != Ja',
    condition: (akte) =>
      akte.PDB_EundL_Lademanagement_Smart1Controller == null ||
      akte.PDB_EundL_Lademanagement_Smart1Controller == undefined ||
      akte.PDB_EundL_Lademanagement_Smart1Controller.trim().toLowerCase() == 'nein',
    patch: {
      PDB_EundL_Lademanagement_DokumentationSmart1Vorhanden: 'hidden',
    },
  },
  TMHChargepilot: {
    description: 'Wenn TMH Chargepilot != Ja',
    condition: (akte) => akte.PDB_EundL_Lademanagement_TMHChargepilot != 'Ja',
    patch: {
      PDB_EundL_Lademanagement_DokumentationTMHVorhanden: 'hidden',
    },
  },
  StatusLademangement: {
    description: 'Wenn Status Lademangement != nicht gewünscht',
    condition: (akte) =>
      akte.PDB_EundL_Lademanagement_Status != 'ErstellungDurchConnectGewuenscht' &&
      akte.PDB_EundL_Lademanagement_Status != 'bereitsVorhanden',
    patch: {
      PDB_EundL_Lademanagement_SeriennummerLLMController: 'hidden',
      PDB_EundL_Lademanagement_ZugangZumLastmanagement: 'hidden',
      PDB_EundL_Lademanagement_IBNProtokollLLEMSvorhanden: 'hidden',
    },
  },
  StatischesLademanagement: {
    description: 'Wenn Statisches Lademanagement != Ja',
    condition: (akte) => akte.PDB_EundL_Anforderung_StatischesLademanagement != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuStatischesMgmntVorhanden: 'hidden',
    },
  },
  DynamischesLademanagement: {
    description: 'Wenn Dynamisches Lademanagement != Ja',
    condition: (akte) => akte.PDB_EundL_Anforderung_DynamischesLademanagement != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuDynamischesMgmntVorhanden: 'hidden',
    },
  },
  Schieflastbegrenzung: {
    description: 'Wenn Schieflastbegrenzung != Ja',
    condition: (akte) => akte.PDB_EundL_Anforderung_Schieflastbegrenzung != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuSchieflastbegrenzungVorhanden: 'hidden',
    },
  },
  PVIntegration: {
    description: 'Wenn PV-Integration != Ja',
    condition: (akte) => akte.PDB_EundL_Anforderung_PVIntegration != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuPVIntegrationVorhanden: 'hidden',
    },
  },
  BatterieIntegration: {
    description: 'Wenn Batteriespeicher-Integration != Ja',
    condition: (akte) => akte.PDB_EundL_Anforderung_BatterieIntegration != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuBatterieIntegrationVorhanden: 'hidden',
    },
  },
  Nutzerpriorisierung: {
    description: 'Wenn Nutzerpriorisierung != Ja',
    condition: (akte) => akte.PDB_EundL_Anforderung_Nutzerpriorisierung != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuNutzerpriorisierungVorhanden: 'hidden',
    },
  },
  Ladepunktpriorisierung: {
    description: 'Wenn Ladepunktpriorisierung != Ja',
    condition: (akte) => akte.PDB_EundL_Anforderung_Ladepunktpriorisierung != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuLadepunktpriorisierungVorhanden: 'hidden',
    },
  },
  ZugangZumLPMSErwuenscht: {
    description: 'Wenn Zugang zum LPMS erwünscht? != Ja',
    condition: (akte) => akte.PDB_Backend_ZugangZumLPMSErwuenscht != 'Ja',
    patch: {
      PDB_Backend_KundenBackend: 'hidden',
      PDB_Backend_Backend_UserAz: 'hidden',
      PDB_Backend_NameUndEMail: 'hidden',
    },
  },
  SchliesszylinderUVausgetauscht: {
    description: 'Wenn Wenn "GP Joule Connect Schließsystem verbaut" != Ja',
    condition: (akte) => akte.PDB_Wartung_SchliesszylinderUVausgetauscht != 'Ja',
    patch: {
      PDB_Wartung_AnsprechpersonSchluessel: 'hidden',
    },
  },
  Kundenbackend: {
    description: 'Wenn Wenn "Kundenbackend" != Ja',
    condition: (akte) => akte.PDB_Backend_KundenBackend?.toLowerCase() != 'ja',
    patch: {
      PDB_Backend_ZugangsdatenKundenBackend: 'hidden',
    },
  },
  LadepunkteOeffentlich: {
    description: 'Wenn Wenn "Ladepunkt öffentlich" != Ja',
    condition: (akte) =>
      akte.PDB_Backend_LadepunkteOeffentlich == 'LS_in_Fremdbackend' ||
      akte.PDB_Backend_LadepunkteOeffentlich == undefined ||
      akte.PDB_Backend_LadepunkteOeffentlich == "Nein",
    patch: {
      PDB_Backend_EVSEID_QRCode: 'hidden',
      PDB_Backend_VerkaufskostensatzAC: 'hidden',
      PDB_Backend_VerkaufskostensatzDC: 'hidden',
      PDB_Backend_AnzeigeInApp: 'hidden',
      PDB_Backend_Zeittarif_AC: 'hidden',
      PDB_Backend_Zeittarif_DC: 'hidden',
    },
  },
  thqAusblenden: {
    description: 'Wenn Wenn "Ladepunkt öffentlich" == Nein',
    condition: (akte) =>
      akte.PDB_Backend_LadepunkteOeffentlich == "Nein",
    patch: {
      PDB_Backend_THGQuote: 'hidden',
    },
  },
  LadepunkteOeffentlich_ja: {
    description: 'Wenn Wenn "Ladepunkt öffentlich" == ja',
    condition: (akte) =>
      akte.PDB_Backend_LadepunkteOeffentlich == 'ja',
    patch: {
      PDB_Backend_Direct: 'hidden',
    },
  },
  PDB_Backend_Ladestationsname_Fremd: {
    description: 'Wenn "Ladepunkt öffentlich" == LS in Fremdbackend',
    condition: (akte) =>
      akte.PDB_Backend_LadepunkteOeffentlich != 'LS_in_Fremdbackend' ||
      akte.PDB_WasVerkauft == 'ACundDCohne',
    patch: {
      PDB_Backend_Ladestationsname_Fremd: 'hidden',
    },
  },
  Zusatzgarantie: {
    description: 'Wenn Wenn "Wenn zusätzliche Garantie beim Hersteller" != Ja',
    condition: (akte) => akte.PDB_Wartung_Herstellergarantie != 'Ja',
    patch: {
      PDB_Wartung_Herstellergarantie_Laufzeit: 'hidden',
    },
  },
  VerkauftACundDCohne: {
    description: 'Wenn DC und AC ohne Konfig. Verkauft wurde',
    condition: (akte) => akte.PDB_WasVerkauft == 'ACundDCohne',
    patch: {
      PDB_ProjektiererId: 'hidden',
      PDB_Ansprechpartner_TBFId: 'hidden',
      BC_UStIdNr_Kunde: 'hidden',
      UE_Kundendaten_LIS_Standort_Strasse: 'hidden',
      UE_Kundendaten_LIS_Standort_PLZ: 'hidden',
      UE_Kundendaten_LIS_Standort_Stadt: 'hidden',
      PDB_Grundstueckseigentuemer_abweichend_LIS: 'hidden',
      PDB_Anschlussnutzer_abweichend_LIS: 'hidden',
      PDB_LISNutzbareLeistung: 'hidden',
      UE_Ladestationen_TypLadestationAC: 'hidden',
      UE_Ladestationen_AnzahlLadepunkteAC: 'hidden',
      UE_Ladestationen_LadeleistungJeLadepunktAC: 'hidden',
      UE_Ladestationen_Gesamtleistung: 'hidden',
      UE_Ladestationen_AnzahlLadestationenAC: 'hidden',
      UE_Ladestationen_AnzahlStellplaetzeAC: 'hidden',
      AnmeldungBNetzAErforderlich: 'hidden',
      AnmeldungBNetzAErfolgt: 'hidden',
      PDB_Backend_DauerrechnungGemeldet: 'hidden',
      PDB_Backend_ZugangZumLPMSErwuenscht: 'hidden',
      PDB_Backend_LadepunkteOeffentlich: 'hidden',
      PDB_Backend_Type: 'hidden',
      PDB_Backend_AnzeigeInApp: 'hidden',
      PDB_Backend_Direct: 'hidden',
      PDB_Backend_Ladestationsnamen: 'hidden',
      PDB_Backend_Roaming: 'hidden',
      PDB_Backend_THGQuote: 'hidden',
      PDB_Backend_Internetanschluss: 'hidden',
      PDB_Backend_KonfigurationLucomRouter: 'hidden',
      PDB_Backend_LucomConnectRouter: 'hidden',
      PDB_Wartung_IBNProtokoll: 'hidden',
      PDB_Wartung_ProtokollSchluesseluebergabe: 'hidden',
      PDB_Wartung_SchliesszylinderUVausgetauscht: 'hidden',
      PDB_Wartung_AbnahmeProtManegelfrei: 'hidden',
      PDB_Wartung_StromlaufplaeneVorhanden: 'hidden',
      PDB_Wartung_AnlageStoerungsfrei: 'hidden',
      PDB_Wartung_TestladevorgangErfolg: 'hidden',
      PDB_Wartung_ProjektbeschreibungVorhanden: 'hidden',
      PDB_Wartung_FotoDokuVorhanden: 'hidden',
      PDB_Backend_Verbindungsmethode: 'hidden',
      PDB_Backend_Routertyp: 'hidden',
      PDB_Backend_NetzwerkHost: 'hidden',
      PDB_Backend_LucomRouter: 'hidden',
      PDB_Backend_ZusaetzlicheInformationen: 'hidden',
      PDB_EundL_Lademanagement_Status: 'hidden',
      PDB_EundL_Lademanagement_Smart1Controller: 'hidden',
      PDB_EundL_Lademanagement_TMHChargepilot: 'hidden',
      PDB_EundL_Lademanagement_InternesLademanagement: 'hidden',
      PDB_EundL_Anforderung_StatischesLademanagement: 'hidden',
      PDB_EundL_Anforderung_Schieflastbegrenzung: 'hidden',
      PDB_EundL_Anforderung_Ladepunktpriorisierung: 'hidden',
      PDB_Backend_Geolokation_long: 'hidden',
      PDB_Backend_Geolokation_lat: 'hidden',
      PDB_Backend_Kartenterminal: 'hidden',
      PDB_Backend_Waehrung: 'hidden',
      PDB_Backend_Steuersatz: 'hidden',
      PDB_Backend_SteuernummerDesKunden: 'hidden',
      PDB_Backend_EODTime: 'hidden',
      PDB_Backend_PCISO15118: 'hidden',
      PDB_BeschreibungLademanagementkonzept: 'hidden',
      UE_Ladestationen_TypLadestationDC: 'hidden',
      UE_Ladestationen_AnzahlLadepunkteDC: 'hidden',
      UE_Ladestationen_AnzahlLadestationenDC: 'hidden',
      UE_Ladestationen_AnzahlStellplaetzeDC: 'hidden',
      UE_Ladestationen_LadeleistungJeLadepunktDC: 'hidden',
    },
  },
  VerkauftAC: {
    description: 'Wenn Wenn AC inkl. Konfig. Verkauft wurde',
    condition: (akte) => akte.PDB_WasVerkauft == 'AC',
    patch: {
      UE_Ladestationen_TypLadestationDC: 'hidden',
      UE_Ladestationen_AnzahlLadepunkteDC: 'hidden',
      UE_Ladestationen_LadeleistungJeLadepunktDC: 'hidden',
      UE_Ladestationen_AnzahlLadestationenDC: 'hidden',
      UE_Ladestationen_AnzahlStellplaetzeDC: 'hidden',
      PDB_Backend_EODTime: 'hidden',
      PDB_Backend_Kartenterminal: 'hidden',
      PDB_Backend_PCISO15118: 'hidden',
      DC_Standort_Name: 'hidden',
    },
  },
  VerkauftDC: {
    description: 'Wenn Wenn DC inkl. Konfig. Verkauft wurde',
    condition: (akte) => akte.PDB_WasVerkauft == 'DC',
    patch: {
      UE_Ladestationen_TypLadestationAC: 'hidden',
      UE_Ladestationen_AnzahlLadepunkteAC: 'hidden',
      UE_Ladestationen_LadeleistungJeLadepunktAC: 'hidden',
      UE_Ladestationen_AnzahlLadestationenAC: 'hidden',
      UE_Ladestationen_AnzahlStellplaetzeAC: 'hidden',
      AC_Standort_Name: 'hidden',
    },
  },
  suwAnsprechperson: {
    description: 'Wenn Wenn S&W Ansprechperson abweichend von Technischem ansprechpartner',
    condition: (akte) => akte.PDB_Wartung_AnsprechpersonAbweichend != 'Ja',
    patch: {
      PDB_Wartung_Ansprechperson: 'hidden',
      PDB_Wartung_AnsprechpersonTelefon: 'hidden',
      PDB_Wartung_AnsprechpersonEMail: 'hidden',
    },
  },
  sonstigeGewehrleistung: {
    description: 'Wenn Wenn S&W Ansprechperson abweichend von Technischem ansprechpartner',
    condition: (akte) => akte.PDB_Wartung_Gewaehrleistungsart != 'sonstiges',
    patch: {
      PDB_Wartung_GewaehrleistungsartSonstige: 'hidden',
      PDB_Wartung_Gewaehrleistung_Laufzeit: 'hidden',
    },
  },
  plugAndChareNoAuth: {
    description: 'Wenn bei „Authentifizierung“ = ohne Authentifizierung',
    condition: (akte) => akte.PDB_Backend_Type != 'RFID',
    patch: {
      PDB_Backend_Authentifizierung: 'hidden',
    },
  },

  PDB_Auftragsnummer_Nachtrag0IsEmpty: {
    description: "show next field onley if PDB_Auftragsnummer_Nachtrag0 is empty",
    condition: (akte) => (akte.PDB_Auftragsnummer_Nachtrag0 ?? "") == "" && (akte.PDB_Auftragsnummer_Nachtrag1 ?? "") == "",
    patch: {
      PDB_Auftragsnummer_Nachtrag1: 'hidden',
    }
  },
  PDB_Auftragsnummer_Nachtrag1IsEmpty: {
    description: "show next field onley if PDB_Auftragsnummer_Nachtrag1 is empty",
    condition: (akte) => (akte.PDB_Auftragsnummer_Nachtrag1 ?? "") == "" && (akte.PDB_Auftragsnummer_Nachtrag2 ?? "") == "",
    patch: {
      PDB_Auftragsnummer_Nachtrag2: 'hidden',
    }
  },
  PDB_Auftragsnummer_Nachtrag2IsEmpty: {
    description: "show next field onley if PDB_Auftragsnummer_Nachtrag2 is empty",
    condition: (akte) => (akte.PDB_Auftragsnummer_Nachtrag2 ?? "") == "" && (akte.PDB_Auftragsnummer_Nachtrag3 ?? "") == "",
    patch: {
      PDB_Auftragsnummer_Nachtrag3: 'hidden',
    }
  },
  PDB_Auftragsnummer_Nachtrag3IsEmpty: {
    description: "show next field onley if PDB_Auftragsnummer_Nachtrag3 is empty",
    condition: (akte) => (akte.PDB_Auftragsnummer_Nachtrag3 ?? "") == "" && (akte.PDB_Auftragsnummer_Nachtrag4 ?? "") == "",
    patch: {
      PDB_Auftragsnummer_Nachtrag4: 'hidden',
    }
  },

  ident_dienstwagen_wo: {
    description: "only show if is Dienstwagen loading is applied",
    condition: (akte) => (
      akte.ident_dienstwagen != "ja_mieter" && 
      akte.ident_dienstwagen != "ja_dienstwagen"
    ), 
    patch: {
      ident_dienstwagen_wo: 'hidden',
    }
  },

  ident_dienstwagen_wie_mieter: {
    description: "only show if is Dienstwagenfahrer",
    condition: (akte) => (akte.ident_dienstwagen != "ja_dienstwagen"), 
    patch: {
      ident_dienstwagen_wie_dienstwagen: 'hidden',
    }
  },

  ident_dienstwagen_wie_dienstwagen: {
    description: "only show if is Dienstwagenfahrer",
    condition: (akte) => (akte.ident_dienstwagen != "ja_mieter"), 
    patch: {
      ident_dienstwagen_wie_mieter: 'hidden',
    }
  },
  
  ident_dienstwagen_EinsZuEins: {
    description: "only show if ident_dienstwagen: Ja (Mieter oder Dienstwagen)", // soll gezeigt werden wenn dienstwagen = nein?
    condition: (akte) => (
      akte.ident_dienstwagen == 'Nein'
    ), 
    patch: {
      ident_dienstwagen_EinsZuEins: 'hidden',
    }
  },

  ident_dienstwagen_EinsZuEins_Beziehung: {
    description: "only show if EinsZuEins: Ja", // soll gezeigt werden wenn dienstwagen = nein?
    condition: (akte) => (
      akte.ident_dienstwagen_EinsZuEins != 'Ja'
    )||(
      akte.ident_dienstwagen == 'Nein'
    ), 
    patch: {
      ident_dienstwagen_EinsZuEins_Beziehung: 'hidden',
    }
  },
  
  ident_dienstwagen_zusatz: {
    description: "only show if EinsZuEins: Nein",
    condition: (akte) => (
      akte.ident_dienstwagen_EinsZuEins != 'Nein'
    )||(
      akte.ident_dienstwagen == 'Nein'
    ),
    patch: {
      ident_dienstwagen_zusatz: 'hidden',
    }
  },

  ident_dienstwagen_rechnungsempfaenger: {
    description: "only show if Dienstwagenfahrer/mieter = Ja",
    condition: (akte) => (
      akte.ident_dienstwagen != "ja_mieter" && 
      akte.ident_dienstwagen != "ja_dienstwagen"
    ), 
    patch: {
      ident_dienstwagen_rechnungsempfaenger: 'hidden',
    }
  },

  ident_dienstwagen_rechnungsempfaenger_name: {
    description: "only show if rechnungsempfänger = sonstige",
    condition: (akte) => (
      akte.ident_dienstwagen_rechnungsempfaenger !="rechnungsonstiges" ||
      akte.ident_dienstwagen == "Nein"  
    ), 
    patch: {
      ident_dienstwagen_rechnungsempfaenger_name: 'hidden',
    }
  },

  ident_dienstwagen_rechnungsempfaenger_adresse: {
    description: "only show if rechnungsempfänger = sonstige",
    condition: (akte) => (
      akte.ident_dienstwagen_rechnungsempfaenger !="rechnungsonstiges" ||
      akte.ident_dienstwagen == "Nein"  
    ),  
    patch: {
      ident_dienstwagen_rechnungsempfaenger_adresse: 'hidden',
    }
  },

  ident_dienstwagen_rechnungsempfaenger_plz: {
    description: "only show if rechnungsempfänger = sonstige",
    condition: (akte) => (
      akte.ident_dienstwagen_rechnungsempfaenger !="rechnungsonstiges" ||
      akte.ident_dienstwagen == "Nein"  
    ),  
    patch: {
      ident_dienstwagen_rechnungsempfaenger_plz: 'hidden',
    }
  },

  ident_dienstwagen_rechnungsempfaenger_stadt: {
    description: "only show if rechnungsempfänger = sonstige",
    condition: (akte) => (
      akte.ident_dienstwagen_rechnungsempfaenger !="rechnungsonstiges" ||
      akte.ident_dienstwagen == "Nein"  
    ),  
    patch: {
      ident_dienstwagen_rechnungsempfaenger_stadt: 'hidden',
    }
  },

  ident_dienstwagen_rechnungsempfaenger_email: {
    description: "only show if rechnungsempfänger = sonstige|LIS",
    condition: (akte) => (
      (akte.ident_dienstwagen_rechnungsempfaenger !="rechnungsonstiges" && 
      akte.ident_dienstwagen_rechnungsempfaenger !="rechnungLIS") ||
      akte.ident_dienstwagen == "Nein"
    ), 
    patch: {
      ident_dienstwagen_rechnungsempfaenger_email: 'hidden',
    }
  },


  ident_dienstwagen_AP_name: {
    description: "only show if ident_dienstwagen: Ja (Mieter oder Dienstwagen)",
    condition: (akte) => (
      akte.ident_dienstwagen == 'Nein'
    ), 
    patch: {
      ident_dienstwagen_AP_name: 'hidden',
    }
  },

  ident_dienstwagen_AP_email: {
    description: "only show if ident_dienstwagen: Ja (Mieter oder Dienstwagen)",
    condition: (akte) => (
      akte.ident_dienstwagen == 'Nein'
    ), 
    patch: {
      ident_dienstwagen_AP_email: 'hidden',
    }
  },

  ident_dienstwagen_ladekartenBestellung: {
    description: "only show if ident_dienstwagen: Ja (Mieter oder Dienstwagen)",
    condition: (akte) => (
      akte.ident_dienstwagen == 'Nein'
    ), 
    patch: {
      ident_dienstwagen_ladekartenBestellung: 'hidden',
    }
  },

  ident_dienstwagen_sondertarief: {
    description: "only show if Wo=Son-Tarif",
    condition: (akte) => (
      akte.ident_dienstwagen_wie_mieter != "SON_Tarif" ||
      akte.ident_dienstwagen != 'ja_mieter'
    ), 
    patch: {
      ident_dienstwagen_sondertarief: 'hidden',
    }
  },

  ident_privat_wo: {
    description: "only show if Privatwagen/Kurzzeitmieter = Ja",
    condition: (akte) => (
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_wo: 'hidden',
    }
  },

  ident_privat_wie: {
    description: "only show if Privatwagen/Kurzzeitmieter = Ja",
    condition: (akte) => (
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_wie: 'hidden',
    }
  },

  ident_privat_EinsZuEins:{
    description: "only show if Privatwagen/Kurzzeitmieter = Ja",
    condition: (akte) => (
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_EinsZuEins: 'hidden',
    }
  },

  ident_privat_EinsZuEins_Beziehung:{
    description: "only show if Privatwagen/Kurzzeitmieter = Ja",
    condition: (akte) => (
      akte.ident_privat_EinsZuEins != 'Ja' ||
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_EinsZuEins_Beziehung: 'hidden',
    }
  },

  ident_privat_zusatz:{
    description: "only show if Privatwagen/Kurzzeitmieter = Ja",
    condition: (akte) => (
      akte.ident_privat_EinsZuEins != 'Nein' ||
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_zusatz: 'hidden',
    }
  },

  ident_privat_rechnungsempfaenger:{
    description: "only show if Privatwagen/Kurzzeitmieter = Ja",
    condition: (akte) => (
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_rechnungsempfaenger: 'hidden',
    }
  },

  ident_privat_AP_name: {
    description: "only show if ident_privat: Ja",
    condition: (akte) => (
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_AP_name: 'hidden',
    }
  },

  ident_privat_AP_email: {
    description: "only show if ident_privat: Ja",
    condition: (akte) => (
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_AP_email: 'hidden',
    }
  },

  ident_privat_ladekartenBestellung: {
    description: "only show if ident_privat: Ja",
    condition: (akte) => (
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_ladekartenBestellung: 'hidden',
    }
  },

  ident_privat_sondertarief: {
    description: "only show if ident_privat_wo = SON_Tarif",
    condition: (akte) => (
      akte.ident_privat_wie != "SON_Tarif" ||
      akte.ident_privat != 'Ja'
    ), 
    patch: {
      ident_privat_sondertarief: 'hidden',
    }
  },

  ident_mieter_wie: {
    description: "only show if ident_mieter = Ja",
    condition: (akte) => (
      akte.ident_mieter != "Ja"
    ), 
    patch: {
      ident_mieter_wie: 'hidden',
    }
  },

  ident_mieter_EinsZuEins:{ 
    description: "only show if mieter_EinsZuEins = Ja",
    condition: (akte) => (
      akte.ident_mieter != "Ja"
    ), 
    patch: {
      ident_mieter_EinsZuEins: 'hidden',
    }
  },

  ident_mieter_EinsZuEins_Beziehung:{
    description: "only show if mieter_EinsZuEins = Ja",
    condition: (akte) => (
      akte.ident_mieter_EinsZuEins != "Ja" ||
      akte.ident_mieter != "Ja"
    ), 
    patch: {
      ident_mieter_EinsZuEins_Beziehung: 'hidden',
    }
  },

  ident_mieter_zusatz:{
    description: "only show if mieter_EinsZuEins = Nein",
    condition: (akte) => (
      akte.ident_mieter_EinsZuEins != 'Nein' ||
      akte.ident_mieter != "Ja"
    ), 
    patch: {
      ident_mieter_zusatz: 'hidden',
    }
  },

  ident_mieter_rechungsempfaenger:{
    description: "only show if mieter = Ja",
    condition: (akte) => (
      akte.ident_mieter != 'Ja'
    ), 
    patch: {
      ident_mieter_rechnungsempfaenger: 'hidden',
    }
  },

  ident_mieter_AP_name: {
    description: "only show if ident_mieter: Ja",
    condition: (akte) => (
      akte.ident_mieter != 'Ja'
    ), 
    patch: {
      ident_mieter_AP_name: 'hidden',
    }
  },

  ident_mieter_AP_email: {
    description: "only show if ident_mieter: Ja",
    condition: (akte) => (
      akte.ident_mieter != 'Ja'
    ), 
    patch: {
      ident_mieter_AP_email: 'hidden',
    }
  },

  ident_mieter_ladekartenBestellung: {
    description: "only show if ident_mieter: Ja",
    condition: (akte) => (
      akte.ident_mieter != 'Ja'
    ), 
    patch: {
      ident_mieter_ladekartenBestellung: 'hidden',
    }
  },

  ident_mieter_sondertarief: {
    description: "only show if ident_mieter_wie = SON_Tarif",
    condition: (akte) => (
      akte.ident_mieter_wie != "SON_Tarif" ||
       akte.ident_mieter != "Ja"
    ), 
    patch: {
      ident_mieter_sondertarief: 'hidden',
    }
  },



}
