import { Div } from '@expo/html-elements'
import { Text } from '../../../../../components/TextComponents'

import { dsv } from '../../../../../styles/defaults'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'
import { useTranslation } from 'react-i18next'

export interface UebergabeHeaderRowProps {
  headerCols: ProcessUebergabeStepType[]
}

export function UebergabeHeaderRow({
  headerCols
}: UebergabeHeaderRowProps) {

  const { t } = useTranslation()
  
  return (
    <Div style={{ display: 'flex', flexDirection: 'row' }}>
      <Div style={{ width: '15%' }} />
      { headerCols.map( (handover,_idx,arr) => 
        <Div key={handover} style={{ backgroundColor: dsv.colors.GpJouleGelb, width: `${85/arr.length}%` }}>
          <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{t(`uebergabeTitle_${handover}`)}</Text>
        </Div>
      )}
    </Div>
  )
}
