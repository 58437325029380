import { Div } from '@expo/html-elements'
import { H4, Text } from '../../../../components/TextComponents'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useTranslation } from 'react-i18next'

import { dsv } from '../../../../styles/defaults'
import { textWithPlaceHolder, linkWithPlaceholder } from '../../../../utils/text'

import { SubCard } from '../../../../components/SubCard'

export function SubCardKunde() {
  const akte = useAkteContext()
  const { t } = useTranslation()

  const isLoading = akte.isLoading
  if (isLoading) {
    return <></>
  }
  return (
    <SubCard width="32%" title="Kunde">
      <Div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 10,
        }}
      >
        <Div
          style={{
            flexBasis: 150,
            flexGrow: 1,
            borderRadius: 4,
            padding: 10,
            backgroundColor: dsv.colors.connectGrau30,
          }}
        >
          <H4 style={{ marginTop: 10, lineHeight: 0.5, textAlign: 'center' }}>Kontakt</H4>
          <Text>{textWithPlaceHolder(akte.serverState?.BC_Kunde_Name, t)}</Text>
          <Text>
            {textWithPlaceHolder(akte.serverState?.BC_Kunde_Adresse, t)} <br />
            {textWithPlaceHolder(akte.serverState?.BC_Kunde_PLZ, t)}{' '}
            {textWithPlaceHolder(akte.serverState?.BC_Kunde_Stadt, t)}
          </Text>
          <Text>
            {linkWithPlaceholder(akte.serverState?.BC_Kunde_EMail, 'mailTo')}
            <br />
          </Text>
          <Text>{linkWithPlaceholder(akte.serverState?.BC_Kunde_Telefon, 'phone')}</Text>
        </Div>
        <Div
          style={{
            flexBasis: 150,
            flexGrow: 1,
            borderRadius: 4,
            padding: 10,
            backgroundColor: dsv.colors.connectGrau30,
          }}
        >
          <H4 style={{ marginTop: 10, lineHeight: 0.5, textAlign: 'center' }}>Ansprechperson</H4>
          <Text>{textWithPlaceHolder(akte.serverState?.UE_Kundendaten_Ansprechpartner_Name, t)}</Text>
          <Text>{linkWithPlaceholder(akte.serverState?.UE_Kundendaten_Ansprechpartner_EMail, 'mailTo')}</Text>
          <Text>{linkWithPlaceholder(akte.serverState?.UE_Kundendaten_Ansprechpartner_Telefon, 'phone')}</Text>
        </Div>
        <Div
          style={{
            flexBasis: 150,
            flexGrow: 1,
            borderRadius: 4,
            padding: 10,
            backgroundColor: dsv.colors.connectGrau30,
          }}
        >
          <H4 style={{ marginTop: 10, lineHeight: 0.5, textAlign: 'center' }}>Lieferanschrift</H4>
          <Text>
            {textWithPlaceHolder(akte.serverState?.BC_Lieferanschrift_Name, t)} <br />
            {textWithPlaceHolder(akte.serverState?.BC_Lieferanschrift_Strasse, t)} <br />
            {textWithPlaceHolder(akte.serverState?.BC_Lieferanschrift_PLZ, t)}{' '}
            {textWithPlaceHolder(akte.serverState?.BC_Lieferanschrift_Stadt, t)}
            <br />
            {textWithPlaceHolder(akte.serverState?.BC_Lieferanschrift_Land, t)} <br />
          </Text>
        </Div>
      </Div>
    </SubCard>
  )
}
