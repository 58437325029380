import { Dimensions, View } from 'react-native'
import { Outlet } from '../../utils/routing'
import { NavigationDrawer, LinkProps } from '../../components/NavigationDrawer'
import { useCallback, useMemo, useState } from 'react'
import { dsv } from '../../styles/defaults'
import { useAuthContext } from '../../hooks/context/useAuthContext'
import { LoadingScreen } from '../../components/LoadingScreen'
import { UserListContextProvider } from '../../hooks/context/useUserListContext'
import { SuperSign } from '../../components/SuperSign'

import { version } from "@dpa/common/dist";
import { Div } from '@expo/html-elements'
import { Text } from '../../components/TextComponents'

export function PDBLayout() {
  const [expanded, setExpanded] = useState(true)
  const onToggle = useCallback(() => setExpanded(!expanded), [expanded])
  const { hasRole, clearAuthCtx, authCtx } = useAuthContext()

  const navigationDrawerLinks = useMemo(
    () =>
      [
        {
          text: 'Alle Projekte',
          target: '/projects',
          icon: 'home',
        },
        {
          text: 'Meine Projekte & Favoriten',
          target: '/myProjects',
          icon: 'eyeo',
        },
        {
          text: 'Projekt manuell anlegen',
          target: '/createProject',
          icon: 'addfile',
          disabled: !(hasRole('Vertrieb') || hasRole('Innendienst') || hasRole('Admin')),
        },
        { text: 'Mitarbeitende', target: '/mitarbeiter', icon: 'team' },
        { text: 'Abmelden', target: () => clearAuthCtx(), icon: 'logout' },
        // { text: "Home", target: "/", icon: "mail" },
      ] as LinkProps[],
    [clearAuthCtx, hasRole]
  )

  // TODO: add listener for dimension changes
  const ScreenHeight = Dimensions.get('window').height

  if (authCtx?.accessToken == null || authCtx.accessToken.length === 0) {
    return (
      <View style={{ minWidth: '100%' }}>
        <LoadingScreen active />
      </View>
    )
  }

  return (
    <UserListContextProvider>
      <Div style={{position: "fixed", zIndex:100, bottom: 0, left: 0, margin: 10}}>
        <Text>{version.str}</Text>
      </Div>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View
            style={{
              backgroundColor: dsv.colors.connectGrau,
              // TODO: Fix styling for mobile app
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              color: dsv.colors.connectGrau7,
            }}
          >
            <NavigationDrawer expanded={expanded} links={navigationDrawerLinks} onToggleExpansion={onToggle} />
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: dsv.colors.connectGrau7,
              shadowRadius: 20,
              shadowOpacity: 0.2,
              overflow: 'scroll',
              minWidth: 1500,
            }}
          >
            <SuperSign />
            <View
              style={{
                paddingHorizontal: 32,
                paddingVertical: 16,
                maxHeight: ScreenHeight,
                zIndex: 2,
                maxWidth: 1640,
              }}
            >
              {/* <NavBar expanded={expanded} onToggleExpansion={onToggle} /> */}
              <Outlet />
            </View>
          </View>
        </View>
      </View>
    </UserListContextProvider>
  )
}
