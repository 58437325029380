import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import SelectList from '../../SelectList'

import { InputProps } from '.'

export interface SelectProps extends InputProps {
  options?: string[]
  optionsWithKey?: { key: string; value: string }[]
  search?: boolean
  multi?: boolean
  placeholder?: string
}

export function InputSelect({
  strValue,
  setStrValue,
  options,
  optionsWithKey,
  saveStrValue,
  search,
  style,
  placeholder,
}: SelectProps) {
  const { t } = useTranslation()

  const usedOptions = useMemo(() => {
    if (optionsWithKey != null) {
      return optionsWithKey
    }

    return (
      options?.map((s) => ({
        key: s,
        value: t(s),
      })) ?? []
    )
  }, [options, optionsWithKey, t])

  const defaultOption = useMemo(() => usedOptions.find((o) => o.key === strValue) ?? undefined, [usedOptions, strValue])

  const setSelected = useCallback(
    (selectedKey: string) => {
      if (setStrValue != null && defaultOption?.key !== selectedKey && saveStrValue) {
        saveStrValue(selectedKey)
      }
    },
    [setStrValue, defaultOption?.key, saveStrValue]
  )

  return (
    <SelectList
      // TODO: Fix styling for App Version
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      boxStyles={style}
      // TODO: Fix styling for App Version
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      inputStyles={style}
      // TODO: Fix styling for App Version
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dropdownStyles={style}
      search={search}
      disabled={setStrValue == null}
      data={usedOptions}
      defaultOption={defaultOption}
      setSelected={setSelected}
      placeholder={placeholder}
    />
  )
}
