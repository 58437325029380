import { trpc } from '../../App'

export function useAkteInvoices({ id }: { id: number }) {
  const akteInvoices = trpc.akte.invoices.useQuery({ id })
  return akteInvoices
  //  return isLoading
  //    ? {
  //      isLoading: true,
  //      permissions: undefined,
  //    }
  //    : {
  //      isLoading: false,
  //      permissions: permissions!,
  //    }
}
