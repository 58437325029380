import { Div } from '@expo/html-elements'
import { Strong } from '../../TextComponents'
import { dsv } from '../../../styles/defaults'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { addPrefix } from '../../../locales/translationKeys'
import { Ionicons as Icon } from '@expo/vector-icons'


export type DataListColumnHeaderProps<
  TKeys extends string
> = {
  headerHeight: number,
  headerPadding: number,
  field: TKeys,
  sorted: undefined | "asc" | "desc",
  removeField?: (field: TKeys) => void,
  changeSortForField: (field: TKeys) => void,
}

export function DataListColumnHeader<
  TKeys extends string
>(
  {
    headerHeight,
    headerPadding,
    field,
    sorted,
    removeField,
    changeSortForField,
  }: DataListColumnHeaderProps<TKeys>
) {
  const { t } = useTranslation()
  const removeThisField = useCallback(
    () => {
      removeField!(field)
    },
    [t]
  )
  const changeSortForThisField = useCallback(
    () => {
      changeSortForField(field)
    },
    [t]
  )
  return (
    <Div style={{
      height: headerHeight,
      backgroundColor: dsv.colors.GpJouleGelb,
      flexDirection: "row",
      alignItems: "center",
      padding: headerPadding,
    }}>
      <Div
        onPointerDown={changeSortForThisField}
        style={{
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          cursor: 'pointer',
        }}
      >
        <Strong style={{ flexGrow: 1, fontSize: 14, color: dsv.colors.connectGrau }}>
          {t(addPrefix('tableHeaders', field))}
        </Strong>
        <Div style={{ width: 20, height: 20 }}>
          {sorted == undefined
            ? undefined
            : sorted == "asc"
              ? <Icon name="caret-down" size={18} />
              : <Icon name="caret-up" size={18} />
          }
        </Div>
      </Div>

      {removeField == undefined
        ? undefined
        : (
          <Div
            onPointerDown={removeThisField}
            style={{
              width: 20,
              height: 20,
              cursor: 'pointer',
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Icon name="close" size={20} />
          </Div>
        )
      }
    </Div>
  )
}
