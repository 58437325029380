import { CalcAkte } from "@dpa/common/dist"
import { DataList } from "./DataList"
import { Div } from "@expo/html-elements"
import { Text } from "../TextComponents"

export const headerFields = [
  'PDB_Projektnummer',
  'UE_Kundendaten_Projekttitel',
  'PDB_Projektart',
  'PDB_Projekttyp',
  'creationData',
  'UE_Kundendaten_Ansprechpartner_Name',
  'progress',
  'handoverState',
  "PDB_LieferterminVerbindlich",
  "BC_Kunde_Name",

] satisfies Array<keyof CalcAkte> 


interface ProjectListProps {
  projects: Array<CalcAkte> | undefined
  isLoading: boolean
  loadMore: undefined | (() => void)
  dataItemCount?: number | null
  noDataPlaceholder: string
}
export function ProjectList(
  { 
    projects, 
    noDataPlaceholder, 
    isLoading, 
    loadMore,
    dataItemCount
  }: ProjectListProps
) {
  
  return <>
    <DataList
      isLoading={isLoading}
      data={projects}
      loadMore={loadMore}
      dataItemCount={dataItemCount}
      allFieldOptions={headerFields}
      alwaysShowFields={['PDB_Projektnummer', 'UE_Kundendaten_Projekttitel']}
      initFieldsSelectedToShow={[
        "BC_Kunde_Name",
        'PDB_Projektart',
        'handoverState',
        "PDB_LieferterminVerbindlich",
      ]}
      noDataPlaceholder={noDataPlaceholder}
    />
  </>
}
