import { useCallback, useEffect, useMemo, useState, Fragment } from 'react'
import { useAkteContext } from '../context/useAkteContext'
import { useUpdateAkteMutation } from '../update/mutations/useUpdateAkteMutation'
import { useDatenblattFormGroup } from './useDatenblattFormGroup'
import { objDelta } from '../useObjDelta'
import { z } from 'zod'
import { Akte, AkteSchema } from '@dpa/common/dist'
import { Div, TBody, THead, TR, Table } from '@expo/html-elements'
import { TD, TH } from '../../components/TextComponents'
import { useTranslation } from 'react-i18next'
import { calculateTranslationKeys } from '../../components/form/Autoform'
import { datenblattFromSchema } from '../../forms/datenblattFromSchema'
import { dsv } from '../../styles/defaults'

const identTable = {
  collLabels: [
    'Dienstwagenfahrer',
    'Mitarbeiter mit Privatwagen',
    'Mieter (B2C)',
    'Kurzzeitmieter Ferienwohnung (Sonderfall)',
    'Privater Endkunde (reines Roaming)',
  ],
  rows: [
    {
      label: 'IdentMed_WerSollLaden',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_Wer',
        'PDB_Backend_Ident_Privatwagen_Wer',
        'PDB_Backend_Ident_Mieter_Wer',
        'PDB_Backend_Ident_Kurzmieter_Wer',
        'PDB_Backend_Ident_Endkunde_Wer',
      ],
    },
    {
      label: 'IdentMed_WoSollGeladenWerden',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_Wo',
        'PDB_Backend_Ident_Privatwagen_Wo',
        'PDB_Backend_Ident_Mieter_Wo',
        'PDB_Backend_Ident_Kurzmieter_Wo',
        'PDB_Backend_Ident_Endkunde_Wo',
      ],
    },
    {
      label: 'IdentMed_WieSollGeladenWerden',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_Wie',
        'PDB_Backend_Ident_Privatwagen_Wie',
        'PDB_Backend_Ident_Mieter_Wie',
        'PDB_Backend_Ident_Kurzmieter_Wie',
        'PDB_Backend_Ident_Endkunde_Wie',
      ],
    },
    {
      label: 'IdentMed_KostensatzACKostenpflLaden',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_KostensatzAC',
        'PDB_Backend_Ident_Privatwagen_KostensatzAC',
        'PDB_Backend_Ident_Mieter_KostensatzAC',
        undefined,
        undefined,
      ],
    },
    {
      label: 'IdentMed_KostensatzDCKostenpflLaden',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_KostensatzDC',
        'PDB_Backend_Ident_Privatwagen_KostensatzDC',
        'PDB_Backend_Ident_Mieter_KostensatzDC',
        undefined,
        undefined,
      ],
    },
    {
      label: 'IdentMed_EinsZuEinsLadekarteLPLSOderStellplatz',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_EinsZuEins',
        'PDB_Backend_Ident_Privatwagen_EinsZuEins',
        'PDB_Backend_Ident_Mieter_EinsZuEins',
        undefined,
        undefined,
      ],
    },
    {
      label: 'IdentMed_FallsJaWelcheBeziehungLiegtVor',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_EinsZuEinsBeziehung',
        'PDB_Backend_Ident_Privatwagen_EinsZuEinsBeziehung',
        'PDB_Backend_Ident_Mieter_EinsZuEinsBeziehung',
        undefined,
        undefined,
      ],
    },
    {
      label: 'IdentMed_ZusatzinfoLadePZugangsb',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_Zusatzinfo',
        'PDB_Backend_Ident_Privatwagen_Zusatzinfo',
        'PDB_Backend_Ident_Mieter_Zusatzinfo',
        undefined,
        undefined,
      ],
    },
    {
      label: 'IdentMed_Abrechnungsempfaenger',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_Abrechnungsempfaenger',
        'PDB_Backend_Ident_Privatwagen_Abrechnungsempfaenger',
        'PDB_Backend_Ident_Mieter_Abrechnungsempfaenger',
        'PDB_Backend_Ident_Kurzmieter_Abrechnungsempfaenger',
        'PDB_Backend_Ident_Endkunde_Abrechnungsempfaenger',
      ],
    },
    {
      label: 'IdentMed_InhaberDesIdentifikationsmediu',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_Inhaber',
        'PDB_Backend_Ident_Privatwagen_Inhaber',
        'PDB_Backend_Ident_Mieter_Inhaber',
        'PDB_Backend_Ident_Kurzmieter_Inhaber',
        'PDB_Backend_Ident_Endkunde_Inhaber',
      ],
    },
    {
      label: 'IdentMed_ZustaendigerAPKundeFuerLadekarten',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_Zustaendiger',
        'PDB_Backend_Ident_Privatwagen_Zustaendiger',
        'PDB_Backend_Ident_Mieter_Zustaendiger',
        'PDB_Backend_Ident_Kurzmieter_Zustaendiger',
        'PDB_Backend_Ident_Endkunde_Zustaendiger',
      ],
    },
    {
      label: 'IdentMed_LayoutLadekarte',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_LayoutLadekarte',
        'PDB_Backend_Ident_Privatwagen_LayoutLadekarte',
        'PDB_Backend_Ident_Mieter_LayoutLadekarte',
        'PDB_Backend_Ident_Kurzmieter_LayoutLadekarte',
        'PDB_Backend_Ident_Endkunde_LayoutLadekarte',
      ],
    },
    {
      label: 'IdentMed_BenachrLadekartenBedarfsbestellungen',
      fields: [
        'PDB_Backend_Ident_Dienstwagen_BenachrLadekarten',
        'PDB_Backend_Ident_Privatwagen_BenachrLadekarten',
        'PDB_Backend_Ident_Mieter_BenachrLadekarten',
        undefined,
        undefined,
      ],
    },
  ],
} as const

export function useDatenblattForms(
  isOk: (data: Partial<z.infer<typeof AkteSchema>>, key: keyof z.infer<typeof AkteSchema>) => boolean = (data, key) =>
    data[key] != undefined,
  isHidden?: (data: Partial<z.infer<typeof AkteSchema>>, key: keyof z.infer<typeof AkteSchema>) => boolean,
  isFrozen: boolean = false,
  isMandatory?: (data: Partial<z.infer<typeof AkteSchema>>, key: keyof z.infer<typeof AkteSchema>) => boolean
) {
  const akte = useAkteContext()
  const updateAkteMutation = useUpdateAkteMutation()

  const [saveDelta, setSaveDelta] = useState(0)

  const onBlurFnc = useCallback(() => {
    setSaveDelta((x) => x + 1)
  }, [setSaveDelta])

  const formGroups = useDatenblattFormGroup(isOk, onBlurFnc, isHidden, isFrozen, isMandatory)

  useEffect(() => {
    if (akte.setLocalState !== undefined && akte.localState !== undefined) {
      const newData = {
        ...akte.localState,
        ...formGroups.form.validation.valideValue, // Might be a problem
        id: akte.localState.id,
      }
      akte.setLocalState(newData)
      const diff = objDelta(newData, akte.serverState)
      if (Object.values(diff).some((changed) => changed)) {
        const tmp = Object.entries(newData)
          .filter(([key, val]) => diff[key as keyof Akte] && val !== null)
          .map(([key, val]) => [key, val === undefined ? null : val])
        const patch = Object.fromEntries(tmp)
        console.log("update patch", patch)
        updateAkteMutation.mutate({ ...akte.identifier, ...patch })
      }
    } else {
      console.debug('no local state or set function')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    saveDelta,
    formGroups.form.validation.valideValue, // mightfix the problem
  ]) //NOTE: the Dependency array is supposed to NOT contain all deps

  const forms = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(datenblattFromSchema).map(([formName, { fields }]) => {
          return [formName, formGroups.pickGroup(fields)]
        })
      ) as { [name in keyof typeof datenblattFromSchema]: ReturnType<typeof formGroups.pickGroup> },
    [formGroups]
  )
  
  return { formGroups, forms }
}
