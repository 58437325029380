import React from 'react'
import { Akte, CalcAkte } from '@dpa/common/dist'
import { useCallback, useMemo } from 'react'

import { Section } from '@expo/html-elements'
import { Card, CardBody, CardHeader } from '../../../components/Card'

import { useAkteContext } from '../../../hooks/context/useAkteContext'

import { useDatenblattForms } from '../../../hooks/form/useDatenblattForms'
import { useUpdateAkteMutation } from '../../../hooks/update/mutations/useUpdateAkteMutation'
import { LoadingScreen } from '../../../components/LoadingScreen'
import { useUebergabeForms } from './utils'
import { UebergabeHeader } from './components/UebergabeHeader'

import { HistoryCollapsible } from './components/HistoryCollapsible'
import { useCurrentProcessGate } from './hooks/useCurrentProcessGate'
import { ProjectDecisions } from './components/ProjectDecisions'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'

interface UebergabeProps{
  uebergabeTyp: ProcessUebergabeStepType
}

export function Uebergabe({ uebergabeTyp }: UebergabeProps) {
  const akte = useAkteContext()

  const processGate = useCurrentProcessGate(akte, uebergabeTyp)

  const isComplete = useMemo(() => {
    if (processGate == undefined) {
      console.log('processGate', processGate)
      return false
    }
    return Object.entries(processGate).every(
      ([fieldName, needed]) =>
        needed != 'required' ||
        (akte?.serverState != undefined && (akte.serverState as Record<string, unknown>)[fieldName] != undefined)
    )
  }, [akte.serverState, processGate])
  console.log('isComplete', isComplete)

  const isMandatory = useCallback(
    (data: Partial<Akte>, key: keyof Akte): boolean => {
      if (processGate == undefined) return true

      const tmp = processGate[key]

      return tmp == 'required'
    },
    [processGate]
  )

  const isHidden = useCallback(
    (data: Partial<Akte>, key: keyof CalcAkte): boolean => {
      if (processGate == undefined) return true
      const tmp = processGate[key]
      return (
        tmp == 'hidden'
      )
    },
    [processGate]
  )

  const updateAkteMutation = useUpdateAkteMutation()

  const isFrozen = akte.stateHistory == undefined || akte.stateHistory[0].state != `workOn_${uebergabeTyp}`

  const { forms: datenblattForms } = useDatenblattForms(
    (data, key) => {
      return isMandatory(data, key) ? data[key] != undefined : true
    },
    isHidden,
    isFrozen,
    isMandatory
  )

  const forms = useUebergabeForms(datenblattForms)

  return (
    <Section key={uebergabeTyp} style={{ flex: 1 }}>
      <Card customStyle={{ flex: 1 }}>
        {(akte.isLoading || updateAkteMutation.isLoading) && (
          <CardBody customStyle={{ minHeight: 300 }}>
            <LoadingScreen active />
          </CardBody>
        )}
        {!(akte.isLoading || updateAkteMutation.isLoading) && (
          <>
            <CardHeader>
              <UebergabeHeader uebergabeStep={uebergabeTyp} uebergabeBlocked={!isComplete} />
            </CardHeader>
            <ProjectDecisions></ProjectDecisions>
            <CardBody customStyle={{ flex: 1, overflow: 'scroll' }}>
              {forms}
              <HistoryCollapsible identifier={akte.identifier} />
            </CardBody>
          </>
        )}
      </Card>
    </Section>
  )
}
