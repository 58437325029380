import { View } from 'react-native'
import { trpc } from '../../../App'
import { Card, CardBody, CardHeader } from '../../components/Card'

import { ProjectList } from '../../components/ProjectList'
import { CalcAkteSchema } from '@dpa/common/dist'
import { useMemo } from 'react'

export function MyProjects() {
  const watchedProjects = trpc.watchedProjects.getAll.useQuery()
  const assignedProjects = trpc.akte.assigned.useQuery()

  const parsedWatchedProjects = useMemo(
    () => watchedProjects?.data?.map((p) => CalcAkteSchema.parse(p)),
    [watchedProjects.data]
  )
  const parsedAssignedProjects = useMemo(
    () => assignedProjects?.data?.map((p) => CalcAkteSchema.parse(p)),
    [assignedProjects.data]
  )
  return (
    <View>
      <Card customStyle={{ margin: 10, width: 1576 }}>
        <CardHeader>Meine Favoriten</CardHeader>
        <CardBody>
          <ProjectList isLoading={watchedProjects.isLoading} projects={parsedWatchedProjects} noDataPlaceholder="Keine favorisierten Projekte" />
        </CardBody>
      </Card>
      <Card customStyle={{ margin: 10, width: 1576 }}>
        <CardHeader>Beteiligte Projekte</CardHeader>
        <CardBody>
          <ProjectList isLoading={assignedProjects.isLoading} projects={parsedAssignedProjects} noDataPlaceholder="Keine beteiligten Projekte" />
        </CardBody>
      </Card>
    </View>
  )
}
