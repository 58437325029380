import { Div } from '@expo/html-elements'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataListAddField } from './DataListAddField'
import { ProgressBar } from '../../ProgressBar'
import { DataListColumn } from './DataListColumn'

import { NoDataPlaceholder } from '../../../constants'
import { DataListFilters } from './DataListFilters'
import { DataListData } from './dataAndTypes'
import { LoadingScreen } from '../../LoadingScreen'
import { dsv } from '../../../styles/defaults'
import { H2 } from '../../TextComponents'
import { GpjButton } from '../../../pages/pdb/projects/components/GpjButton'

type DataListProps<
  TData extends DataListData<TKeys>,
  TKeys extends string
> = {
  data: undefined | Array<TData>
  allFieldOptions: Array<TKeys>
  initFieldsSelectedToShow: Array<TKeys>
  alwaysShowFields: Array<TKeys>
  isLoading: boolean
  loadMore: undefined | (() => void)
  dataItemCount?: number | null
  noDataPlaceholder: string
  //noDataFoundPlaceholder: string
}

export function DataList<
  TData extends DataListData<TKeys>,
  TKeys extends string
>({
  data,
  allFieldOptions,
  alwaysShowFields,
  initFieldsSelectedToShow,
  isLoading,
  loadMore,
  dataItemCount,
  noDataPlaceholder,
  //noDataFoundPlaceholder,
}: DataListProps<TData, TKeys>) {
  const [sortBy, setSortBy] = useState<{ field: TKeys, by: "asc" | "desc" }>({ field: alwaysShowFields[0], by: "asc" })

  const sortedData = useMemo(() => {
    return [...(data ?? [])].sort((a, b) => {
      let sort: number

      const valA = a[sortBy.field]
      const valB = b[sortBy.field]

      if (valA == valB) {
        sort = 0
      } else if (valA == undefined) {
        sort = -1
      } else if (valB == undefined) {
        sort = 1
      } else {
        sort = valA > valB ? 1 : -1
      }

      return sortBy.by === 'asc' ? sort : -sort
    })
  }, [data, sortBy])

  const allFilterOptions = allFieldOptions
  const [activeFilters, setActiveFilters] = useState<{ [attribute in TKeys]?: { active: boolean, filter: string | undefined } }>({})




  const [fieldsSelectedToShow, setFieldsSelectedToShow] = useState<Array<TKeys>>(initFieldsSelectedToShow)

  const fieldAddOptions = useMemo(
    () => [...allFieldOptions.filter((option) => !(fieldsSelectedToShow.includes(option) || alwaysShowFields.includes(option)))]
    , [fieldsSelectedToShow, alwaysShowFields]
  )


  const addField = useCallback((field: TKeys) => {
    setFieldsSelectedToShow(
      (old) =>
        old.includes(field)
          ? old
          : [...old, field]
    )
  }, [setFieldsSelectedToShow])

  const removeField = useCallback((fieldToRemove: TKeys) => {
    setFieldsSelectedToShow(
      (old) => old.filter(field => field != fieldToRemove)
    )
  }, [setFieldsSelectedToShow])

  const changeSortForField = useCallback((fieldToChange: TKeys) => {
    setSortBy(
      (old) => old.field != fieldToChange
        ? ({ field: fieldToChange, by: 'asc' })
        : ({
          field: old.field,
          by: old.by == 'asc' ? 'desc' : "asc"
        })
    )

  }, [setFieldsSelectedToShow])

  const activeFilterEntries = useMemo(
    () => (Object.entries(activeFilters) as Array<[TKeys, { active: boolean, filter: string | undefined }]>),
    [activeFilters]
  )

  const filteredData = useMemo(
    (
      () => activeFilterEntries.reduce(
        (carry, [field, filter]) => {
          if (!filter.active || filter.filter === undefined) {
            return carry
          }
          const filterToLower = filter.filter.toLowerCase()
          return carry.filter(
            data => {
              if (data[field] as any instanceof Date) {
                return (
                  data[field]
                    ?.toLocaleDateString()
                    .toLowerCase()
                    .includes(filterToLower)
                )
              } else {
                return (
                  String(data[field])
                    .toLowerCase()
                    .includes(filterToLower)
                )
              }
            }
          )
        },
        sortedData
      )
    ),
    [sortedData, activeFilterEntries]
  )

  const isFilterActive = useMemo(
    () => activeFilterEntries.some(([_, { active }]) => active),
    [activeFilterEntries]
  )

  const dataToDisplay = filteredData

  const { t } = useTranslation()
  const formatData = useCallback(
    (field: TKeys, data: TData) => {
      const fieldValue = data[field]
      switch (field) {
        case 'progress':
          return <ProgressBar value={fieldValue} width={'90%'} displayPercentage />
        case 'projectStatus':
          return t(fieldValue)
      }
      switch (typeof fieldValue) {
        case 'number':
          return String(fieldValue)
        case 'string':
          return t(fieldValue)
        case 'object':
          {
            if ((fieldValue as any) instanceof Date) {
              return (fieldValue as Date).toLocaleDateString()
            }
          }
      }
      return NoDataPlaceholder
    },
    [t]
  )

  const headerHeight = 45
  const headerPadding = 12
  return (
    <Div style={{ display: "flex", flexDirection: "row", width: "100%", minHeight: 300, overflowX: "scroll", overflowY: "show" }}>
      <Div style={{
        width: 180,
      }}>
        <DataListAddField
          height={headerHeight}
          padding={headerPadding}
          fieldOptions={fieldAddOptions}
          addField={addField}
        />
        <DataListFilters
          data={data}
          allFilterOptions={allFilterOptions}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      </Div>
      <Div style={{ flexGrow: 1 }}>
        <Div style={{
          width: '100%',
          display: "flex",
          flexDirection: "row",
        }}>
          {
            alwaysShowFields.map((fieldToShow) => (
              <DataListColumn
                headerHeight={headerHeight}
                headerPadding={headerPadding}
                key={fieldToShow}
                field={fieldToShow}
                data={dataToDisplay}
                sorted={sortBy.field == fieldToShow ? sortBy.by : undefined}
                changeSortForField={changeSortForField}
                formatData={formatData}
              />
            ))
          }
          {
            fieldsSelectedToShow.map((fieldToShow) => (
              <DataListColumn
                headerHeight={headerHeight}
                headerPadding={headerPadding}
                key={fieldToShow}
                field={fieldToShow}
                data={dataToDisplay}
                sorted={sortBy.field == fieldToShow ? sortBy.by : undefined}
                removeField={removeField}
                changeSortForField={changeSortForField}
                formatData={formatData}
              />
            ))
          }
        </Div>
        {loadMore && dataToDisplay != undefined && data?.length && dataItemCount != undefined &&
          <Div style={{
            width: '100%',
            height: 40,
            alignContent: 'center',
            alignItems: 'center',
          }}>
            <GpjButton
              text={data.length == dataItemCount ? 'Vollständig geladen' : `Mehr Laden (${Math.round(data.length / dataItemCount * 100).toFixed(0)}% geladen)`}
              onPress={loadMore}
              isDisabled={isLoading || data.length == dataItemCount}
              isLoading={isLoading}
            />
          </Div>
        }
        {dataToDisplay == undefined || dataToDisplay.length == 0
          ? <>
            {isLoading && (
              <Div style={{ height: 155 }}>
                <LoadingScreen active />
              </Div>
            )}
            {!isLoading && dataToDisplay.length == 0 && (
              <Div
                style={{
                  width: "100%",
                  height: 155,
                  backgroundColor: dsv.colors.connectGrau7,
                  position: 'relative',
                }}
              >
                <H2
                  style={{
                    margin: 'auto',
                    position: 'relative',
                  }}
                >
                  {noDataPlaceholder}
                  {isFilterActive ? ' gefunden' : ''}
                </H2>
              </Div>
            )}
          </>
          : <>
          </>
        }
      </Div>
    </Div>
  )
}
