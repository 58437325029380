import { View } from 'react-native'

import { Section, Div } from '@expo/html-elements'
import { Card, CardBody } from '../../../../components/Card'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { LoadingScreen } from '../../../../components/LoadingScreen'

import { useAllPermissions } from '../../../../hooks/useAllPermissions'
import { useUserListContext } from '../../../../hooks/context/useUserListContext'
import { useProcessGateContext } from '../../../../hooks/context/useProcessGateContext'

import { useAkteSalesLines } from '../../../../hooks/useAkteSalesLines'
import { ProjectCardHeader } from '../components/ProjectCardHeader'
import { SubCardKunde } from './SubCardKunde'
import { SubCardDetails } from './SubCardDetails'
import { SubCardAnsprechpartner } from './SubCardAnsprechpartner'
import { SubCardTransitionHistory } from './SubCardTransitionHistory'
import { HistoryCollapsible } from '../components/HistoryCollapsible'
import { ProjectDecisions } from '../components/ProjectDecisions'
import { SubCardUebergaben } from './SubCardUebergaben'

export function ProjectOverview() {
  const akte = useAkteContext()
  const { isLoading: usersIsLoading } = useUserListContext()


  const salesLines = useAkteSalesLines(akte.identifier)
  const allPermissions = useAllPermissions()

  const processGateCtx = useProcessGateContext()

  const isLoading = akte.isLoading || allPermissions.isLoading || processGateCtx.isLoading || salesLines.isLoading

  return (
    <Section style={{ flex: 1 }}>
      <Card customStyle={{ flex: 1 }}>
        {(isLoading || usersIsLoading) && (
          <CardBody customStyle={{ minHeight: 300 }}>
            <LoadingScreen active />
          </CardBody>
        )}
        {!isLoading && (
          <>
            <ProjectCardHeader />
            <ProjectDecisions />
            <CardBody
              customStyle={{
                flex: 1,
                overflow: 'scroll',
                padding: 50,
                paddingTop: 25,
                paddingButton: 25,
              }}
            >
              <View style={{}}>
                <Div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: 10,
                    marginBottom: 25,
                  }}
                >
                  <SubCardKunde />
                  <SubCardDetails />
                  <SubCardAnsprechpartner />
                </Div>
                <SubCardUebergaben />
                <SubCardTransitionHistory />
              </View>
              <HistoryCollapsible identifier={akte.identifier} />
            </CardBody>
          </>
        )}
      </Card>
    </Section>
  )
}
