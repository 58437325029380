import { StyleSheet } from 'react-native'

import { Table, TBody, TR, THead } from '@expo/html-elements'

import { dsv } from '../../../../../styles/defaults'
import { TH, TD, H4 } from '../../../../../components/TextComponents'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAkteContext } from '../../../../../hooks/context/useAkteContext'
import { StateHistoryEntry } from '@dpa/server/projectFlow/operations'

const tableStyle = StyleSheet.create({
  td: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 140,
  },
  th: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 140,
  },
  cellBorder: {
    borderBottomWidth: 1,
    borderBottomColor: dsv.colors.connectGrau7,
    borderBottomStyle: 'solid',
    borderSpacing: 10,
  },
})

interface TransitionLogProps {

}
export function TransitionLog() {
  const akte = useAkteContext()
  const isLoading = akte.isLoading

  const { t } = useTranslation()

  // const entries = akte.stateHistory.map((historyEntry) => <HistoryEntry key={historyEntry.at.getTime()} {...historyEntry} />)

  const transitionLogItem = useMemo(
    () => {
      if (akte.stateHistory != null && akte.stateHistory.length > 0) {
        return akte.stateHistory.map(
          transitionItem => <TransitionLogItem key={transitionItem.at.getTime()} item={transitionItem} />
        )
      } else {
        return <TR><TD colSpan={4}>Keine Einträge gefunden</TD></TR>
      }
    },
    [akte.stateHistory]
  )
  if (isLoading || akte.stateHistory.length == 0) return undefined

  return (
    <>
      <Table
        style={{
          backgroundColor: dsv.colors.connectGrau7,
          borderCollapse: 'collapse',
          marginVertical: 12,
          width: '100%',
        }}
      >
        <THead style={{ backgroundColor: dsv.colors.GpJouleGelb }}>
          <TR style={[tableStyle.cellBorder /* TODO: Mehrfabig, jede zweite zeile grau, oder strich zwischen die einträge */]}>
            <TH style={[tableStyle.th, { width: 200 }]}>
              <H4>Geändert am</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Ereignis</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Art</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Kommentar</H4>
            </TH>
          </TR>
        </THead>
        <TBody>
          {transitionLogItem}
        </TBody>
      </Table>
    </>
  )
}

interface TransitionLogItemProps {
  item: StateHistoryEntry
}

export function TransitionLogItem({ item }: TransitionLogItemProps) {
  const { t } = useTranslation()
  return (
    <TR style={tableStyle.cellBorder}>
      <TD style={[tableStyle.td, { width: 200 }]}>
        {`${item.at.toLocaleDateString()} ${item.at.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
      </TD>
      <TD style={tableStyle.td}>
        {t("transitionFiredText_" + item.transitionName)}<br /> {t("stateHistoryText_" + item.state)}
      </TD>
      <TD style={tableStyle.td}>
        {item.transitionName.startsWith("decision_") ? "Entscheidung" : "Projektstatus" /*  */}
      </TD>
      <TD style={tableStyle.td}>
        {item.comment}
      </TD>
    </TR>
  )
}