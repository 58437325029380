import { Div } from '@expo/html-elements'
import { B, Text } from '../../../../components/TextComponents'
import { dsv } from '../../../../styles/defaults'
import { ReactNode } from 'react'

export interface InfoItemProps {
  darkerLine?: boolean
  name: string
  value: string | number | ReactNode
  largerLeft?: boolean
}

export function InfoItem({ name, value, darkerLine, largerLeft }: InfoItemProps) {
  return (
    <Div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '98%',
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        textAlign: 'left',
        flexWrap: 'wrap',
        backgroundColor: darkerLine == true ? dsv.colors.connectGrau7 : undefined,
        marginLeft: 5,
        borderColor: dsv.colors.connectGrau30,
        borderStyle: 'solid',
        borderBottomWidth: 1,
        marginVertical: 5,
      }}
    >
      <Div style={{ 
        paddingLeft: 15, 
        width: largerLeft==true ? '35%' : '28%' 
      }}>
        <B>{name}</B>
      </Div>
      <Div
        style={{
          width: largerLeft==true ? '61%' : '68%',
        }}
      >
        <Text>{value}</Text>
      </Div>
    </Div>
  )
}
