import { DimensionValue } from 'react-native'
import { CalcAkte } from '@dpa/common/dist'

export type SortableTableData = CalcAkte & { progress: number }

type FieldWidth = {
  min: DimensionValue
  max: DimensionValue
}

export type DataListData<
  TKeys extends string
> = { [key in TKeys]?: any }


export const headerFieldStyles = {
  PDB_Projektnummer: { minWidth: 110, maxWidth: 140 },
  UE_Kundendaten_Projekttitel: { minWidth: 310, maxWidth: 400 },
  BC_Kunde_Name: { minWidth: 110, maxWidth: 160 },
  PDB_Projektart: { minWidth: 165, maxWidth: 220 },
  PDB_Projekttyp: { minWidth: 150, maxWidth: 150 },
  creationData: { minWidth: 140, maxWidth: 140 },
  UE_Kundendaten_Ansprechpartner_Name: { minWidth: 150, maxWidth: 180 },
  projectStatus: { minWidth: 240, maxWidth: 240 },
  progress: { minWidth: 150, maxWidth: 180 },
  PDB_LieferterminVerbindlich: {},
} as const
