import { useState, useCallback, ChangeEvent } from 'react'
import { View, Button } from 'react-native'
import { InputSelect } from '../../../../components/form/AutoFormInputFields/InputSelect'
import { dsv } from '../../../../styles/defaults'
import { Span } from '@expo/html-elements'
import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useTranslation } from 'react-i18next'

interface TransitionCommentDialogProps {
  transitionOptions: Array<string>
  closeDialoge: () => void
}

export function TransitionCommentDialog({ transitionOptions, closeDialoge }: TransitionCommentDialogProps) {
  const akte = useAkteContext()

  const {t} = useTranslation()

  const [rejectionText, setRejectionText] = useState('')
  const [selectedTransition, setSelectedTransition] = useState<string>(transitionOptions[0])

  const rejectionTextChangeHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setRejectionText(event.target.value)
    },
    [setRejectionText]
  )
  console.log("selectedTransition", selectedTransition)
  console.log("rejectionText", rejectionText)
  const reject = useCallback(()=>{
    if(akte.performTransition){
      akte.performTransition(selectedTransition, rejectionText)
      closeDialoge()
    }
  },[akte, rejectionText, selectedTransition])

  
  const hasComment = rejectionText.length > 0

  return (
    <View style={{ marginLeft: 'auto', width: 335 }}>
      <textarea
        style={{ resize: 'vertical' }}
        rows={5}
        onChange={rejectionTextChangeHandler}
        placeholder={"Begründung angeben."}
      ></textarea>
      <View style={{
        margin: 5,
        paddingBottom: 5,
        backgroundColor: dsv.colors.connectGrau7,
        color: dsv.colors.black,
        fontSize: 12,
      }}>
        {
          transitionOptions.length > 1 
            ? <InputSelect
              fieldId='rejectionType'
              strValue={selectedTransition ?? ''}
              setStrValue={setSelectedTransition}
              saveStrValue={setSelectedTransition}
              options={transitionOptions}
            />
            :undefined
        }
        <Button disabled={!hasComment} color={'rgb(180, 33, 33)'} title={t(selectedTransition.startsWith("returnFrom_") ? "btnTxt_returnFrom" : selectedTransition)} onPress={reject} />
      </View>
    </View>
  )
}
