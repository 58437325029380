import { View } from 'react-native'
import { Outlet } from '../../../utils/routing'

import { ProjectTabs } from './components/ProjectTabs'
import { AkteContextProvider } from '../../../hooks/context/useAkteContext'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { ProcessGateContextProvider } from '../../../hooks/context/useProcessGateContext'

export function ProjectsLayout() {
  const { projectId: projectIdStr } = useParams()
  // TODO: useParams might return undefined ?
  const projectId = useMemo(() => Number.parseInt(projectIdStr!), [projectIdStr])

  return (
    <ProcessGateContextProvider>
      <AkteContextProvider id={projectId}>
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row' }}>
            <ProjectTabs/>
          </View>
          <Outlet />
        </View>
      </AkteContextProvider>
    </ProcessGateContextProvider>
  )
}
