import {
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useCallback,
} from 'react'

import { trpc } from '../../../App'
import { CalcAkte,  CalcAkteSchema, AktenIdentifier } from '@dpa/common/dist'
import { useObjDelta } from '../useObjDelta'
import { ProcessTypeType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessTypeSchema'
import { AkteStateEnumType, AkteStateEnumSchema } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/AkteStateEnumSchema'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'
import { z } from 'zod'
import { AvailableTransitions } from '@dpa/server/projectFlow/operations'


export const StateHistoryEntrySchema = z.object({
  transitionName: z.string(),
  state: AkteStateEnumSchema,
  at: z.coerce.date(),
  comment: z.string().nullish()
})
export type StateHistoryEntry = z.infer<typeof StateHistoryEntrySchema>

export const StateHistorySchema = StateHistoryEntrySchema.array()
export type StateHistory = z.infer<typeof StateHistorySchema>


// TODO: why cant i import this from common ?
export function calculateProcessType(stateHistory: StateHistory): ProcessTypeType | undefined {
  const startState = stateHistory.find(historyEntry => historyEntry.state.startsWith("type_"))
  if(startState == undefined) return undefined

  switch(startState.state){
    case "type_Elektifizierung_AC":
      return "Elektifizierung_AC"
    case "type_Elektifizierung_DC":
      return "Elektifizierung_DC"
    case "type_komplexe_DC_inclIBN":
      return "komplexe_DC_inclIBN"
    case "type_standart_DC_inclIBN":
      return "standart_DC_inclIBN"
    case "type_auslieferung_DC":
      return "auslieferung_DC"
    case "type_ac_inc_config":
      return "ac_inc_config"
    default:
      return undefined
    }
}


// TODO: why cant i import this from common ?
export function calculateUebergabeStep(stateHistory: StateHistory): ProcessUebergabeStepType | undefined {
  const workonTransition = stateHistory.find(state => state.state.startsWith("workOn_"))
  if(workonTransition == undefined) return undefined
  
  switch(workonTransition.state){
    case "workOn_Vertrieb2PE":
      return "Vertrieb2PE"
    case "workOn_PE2Vertrieb":
      return "PE2Vertrieb"
    case "workOn_Vertrieb2ENG":
      return "Vertrieb2ENG"
    case "workOn_ENG2Vertrieb":
      return "ENG2Vertrieb"
    case "workOn_Vertrieb2Projektleitung":
      return "Vertrieb2Projektleitung"
    case "workOn_Projektleitung2SuW":
      return "Projektleitung2SuW" 
    case "workOn_SuW":  // TODO: add archive handover
      return "Projektleitung2SuW" 
    //"workOn_SuW"
  }
}


export type ServerStateCtx<TData> =
  | {
      identifier: AktenIdentifier
      isLoading: true
      serverState?: TData
      localState?: TData
      setLocalState?: Dispatch<SetStateAction<TData>> // TODO: Maybe only allow setting of non calculated fields ?
      localDiverges?: undefined
      localDivergences?: { [key in keyof TData]?: boolean }
      availableTransitions?: AvailableTransitions | undefined
      stateHistory?: StateHistory
      performTransition?: (transitionName: string, comment?: string) => void
      processUebergabeStep?: ProcessUebergabeStepType | undefined
      processType?: ProcessTypeType
      performTransitionLoading?: boolean
      isWatched?: boolean
    }
  | {
      identifier: AktenIdentifier
      isLoading: false
      serverState: TData
      localState: TData
      setLocalState: Dispatch<SetStateAction<TData>>
      localDiverges: boolean
      localDivergences: { [key in keyof TData]?: boolean }
      availableTransitions: AvailableTransitions
      performTransition: (transitionName: string, comment?: string) => void
      processType?: ProcessTypeType
      processUebergabeStep: ProcessUebergabeStepType | undefined
      stateHistory: StateHistory
      performTransitionLoading: boolean
      isWatched: boolean
      toggleWatched: () => void
    }

export type AkteCtx = ServerStateCtx<CalcAkte>

const AkteContext = createContext<AkteCtx | null>(null)

export interface AkteContextProviderProps {
  id: number
}

export function AkteContextProvider({ id, children }: PropsWithChildren<AkteContextProviderProps>) {
  const trpcCtx = trpc.useUtils()
  const project = trpc.akte.byId.useQuery({ id })
  const isWatched = trpc.watchedProjects.isWatched.useQuery({ akteId: id })
  const watch = trpc.watchedProjects.watch.useMutation({
    onSuccess: () => {
      trpcCtx.watchedProjects.isWatched.invalidate({ akteId: id })
    },
  })
  const unWatch = trpc.watchedProjects.unWatch.useMutation({
    onSuccess: () => {
      trpcCtx.watchedProjects.isWatched.invalidate({ akteId: id })
    },
  })

  const availableTransitions = trpc.transition.availableTransitions.useQuery({id})
  const stateHistory = trpc.transition.stateHistory.useQuery({id})
  const performTransitionMutation = trpc.transition.performTransitions.useMutation({
    onSuccess: () => {
      trpcCtx.transition.availableTransitions.invalidate({ id })
      trpcCtx.transition.stateHistory.invalidate({ id })
    },
  })

  const performTransition = useCallback(
    (transitionName: string, comment?: string) => performTransitionMutation.mutate({transitionName, akteId: id, comment})
    ,[performTransitionMutation, id]
  )

  const [localState, setLocalState] = useState<CalcAkte | undefined>(undefined)

  if (project.error?.data?.code === 'NOT_FOUND' || (!project.isLoading && project.data === undefined)) {
    throw new Error('Akte Not found') // TODO: error Boundary
  }

  const serverState = useMemo(() => {
    if (project.data === undefined) {
      return undefined
    }
    return CalcAkteSchema.parse(project.data)
  }, [project.data])

  useEffect(() => {
    if (serverState !== undefined) {
      //const delta = objDelta(serverState, localState)
      //if (Object.values(delta).some((isDifferent) => isDifferent)) {
      if (localState == undefined) {
        setLocalState(serverState) // TODO: when to update local State with Server State ?
      }
    }
  }, [localState, serverState])

  const localDivergences = useObjDelta(serverState, localState)
  const localDiverges = useMemo(
    () => Object.values(localDivergences).some(fieldChanged => fieldChanged),
    [localDivergences]
  )

  const stateHistoryParsed = useMemo(
    ()=> stateHistory.data == undefined ? undefined : StateHistorySchema.parse(stateHistory.data),
    [stateHistory.data]
  )

  const processType = useMemo(
    ()=> stateHistoryParsed == undefined ? undefined : calculateProcessType(stateHistoryParsed),
    [stateHistoryParsed]
  )

  const processUebergabeStep = useMemo(
    ()=> stateHistoryParsed == undefined ? undefined : calculateUebergabeStep(stateHistoryParsed),
    [stateHistoryParsed]
  )
  

  const ctxValue: AkteCtx = useMemo(() => {
    if (
      isWatched.isLoading || 
      project.isLoading || 
      serverState == undefined || 
      localState == undefined || 
      availableTransitions.isLoading ||
      stateHistory.isLoading
    ) {
      return {
        identifier: { id },
        isLoading: true,
        localState,
        serverState,
        setLocalState: localState === undefined ? undefined : (setLocalState as Dispatch<SetStateAction<CalcAkte>>),
        isWatched: isWatched.data?.watched,
      }
    } else {
      return {
        identifier: { id },
        isLoading: false,
        serverState,
        localState: localState,
        setLocalState: setLocalState as Dispatch<SetStateAction<CalcAkte>>,
        localDiverges,
        localDivergences,
        isWatched: isWatched.data!.watched,
        availableTransitions: availableTransitions.data,  // warum kann hier ein undefined sein ?
        processType,
        performTransition,
        processUebergabeStep,
        stateHistory: stateHistoryParsed,
        performTransitionLoading: performTransitionMutation.isLoading,
        toggleWatched: isWatched.data!.watched
          ? () => unWatch.mutate({ akteId: id })
          : () => watch.mutate({ akteId: id }),
      }
    }
  }, [
    isWatched.isLoading,
    isWatched.data,
    project.isLoading,
    serverState,
    localState,
    availableTransitions.isLoading,
    availableTransitions.data,
    stateHistory,
    id,
    localDiverges,
    localDivergences,
    processType,
    performTransition,
    performTransitionMutation.isLoading,
    unWatch,
    watch,
  ])

  return <AkteContext.Provider value={ctxValue}>{children}</AkteContext.Provider>
}

export function useMaybeAkteContext() {
  return useContext(AkteContext)
}

export function useAkteContext() {
  const ctx = useContext(AkteContext)
  if (ctx == null) {
    throw new Error('usage of useAkteContext outside of a AkteContextProvider')
  }
  return ctx
}
