import { useCallback, useEffect, useMemo } from 'react'

import { trpc } from '../../../App'
import { Button, View } from 'react-native'
import { Section } from '@expo/html-elements'
import { useAutoFormFields } from '../../hooks/form/generic/useAutoForm'
import { Card, CardBody, CardHeader } from '../../components/Card'
import { Text } from '../../components/TextComponents'
import { Col, Row } from '../../components/Row'
import { ProjektAnlageForm, ProjektAnlageFormFields } from '../../forms/projektAnlage'
import { dsv } from '../../styles/defaults'
import { SubCard } from '../../components/SubCard'

export function CreateProject() {
  const trpcCtx = trpc.useUtils()

  const createProjectMutation = trpc.akte.create.useMutation({
    onSuccess: () => trpcCtx.akte.getAll.invalidate(),
  })

  //const { formFields, validation } = useAutoFormFields(ProjektdatenForm, showValidationErrors)
  const { formFieldEntries, validation } = useAutoFormFields(ProjektAnlageForm, ProjektAnlageFormFields)

  const formFields = useMemo(() => formFieldEntries.map(([_, formField]) => formField), [formFieldEntries])

  const createProject = useCallback(() => {
    if (validation.errors == undefined) {
      createProjectMutation.mutate({
        ...validation.valideValue,
        PDB_Backend_Vorautorisierungsbetrag: 9.6,
      })
    }
  }, [validation, createProjectMutation])

  useEffect(() => {
    if (createProjectMutation.data?.id) {
      location.replace(`projects/${createProjectMutation.data?.id}`)
    }
  }, [createProjectMutation.data?.id])

  return (
    <Section>
      <Row>
        <Col cols={12}>
          <Card>
            <CardHeader>
              <Text style={dsv.text}>Projekt manuell Anlegen</Text>
            </CardHeader>
            <CardBody>
              <SubCard title="Initialdaten für manuelle Anlage">
                <View style={{ paddingBottom: 10 }}>{formFields}</View>
                <Button onPress={createProject} title="Anlegen" />
              </SubCard>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  )
}
