import { View } from 'react-native'

import { H3, Text } from '../../../../components/TextComponents'

import { RejectionDialog } from './RejectionDialog'

import { useCallback, useState, useMemo } from 'react'
import { dsv } from '../../../../styles/defaults'
import { useAkteContext } from '../../../../hooks/context/useAkteContext'
//import { useUpdateAkteMutation } from '../../../../hooks/update/mutations/useUpdateAkteMutation'
import { useAuthContext } from '../../../../hooks/context/useAuthContext'
import { GpjButton } from './GpjButton'
import { useTranslation } from 'react-i18next'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'
import { availableTransition } from '@dpa/server/projectFlow/operations'
import { FlowUiType } from '@dpa/server/projectFlow/base'
import { satisfiesRoleSpecifier } from '../../../../hooks/roleBasedDisplay/useUebergabenToDisplay'
import { Div } from '@expo/html-elements'
import { TransitionCommentDialog } from './TransitionCommentDialog'

interface UebergabeHeaderProps {
  uebergabeStep: ProcessUebergabeStepType
  uebergabeBlocked: boolean
}


function FlowUiType2Color(typ: FlowUiType | undefined) {
  switch (typ) {
    case "request":
      return undefined
    case "approve":
      return dsv.colors.success
    case "reject":
    case "revoke":
      return dsv.colors.error
  }
}

export function UebergabeHeader({ uebergabeStep, uebergabeBlocked }: UebergabeHeaderProps) {
  const { t } = useTranslation()
  const akte = useAkteContext()

  const { authCtx } = useAuthContext()

  const [transitionOptions, setTransitionOptions] = useState<Array<string> | undefined>(undefined)

  const closeDialog = useCallback(() => {
    setTransitionOptions(undefined)
  }, [setTransitionOptions])

  console.log("uebergabeBlocked", uebergabeBlocked)
  console.log("akte.availableTransitions", akte.availableTransitions)

  const buttons = useMemo(() => {
    if (akte.processUebergabeStep != uebergabeStep) {
      return <Text
        key={"notActive"}
        style={{
          fontSize: 18,
        }}
      >Aktuelle in {t(`uebergabeTitle_${akte.processUebergabeStep}`)}</Text>
    }

    if (akte.availableTransitions == undefined) return []
    const nonDecissionTransitions = akte.availableTransitions
      .filter(availableTransition => availableTransition[1].meta?.flowUi != "question")

    const nonRevokeActions = nonDecissionTransitions
      .filter(availableTransition => availableTransition[1].meta?.flowUi != "revoke")
      .map(([transitionName, transitionDefinition]) => {
        const hasPermissions = transitionDefinition.meta?.allowedRoles?.some(
          allowedRole => satisfiesRoleSpecifier(authCtx?.roles, allowedRole)
        )
        console.log(" >> ", transitionName, transitionDefinition, hasPermissions)
        if (!hasPermissions) {
          return <Div key={transitionName}
            style={{
              marginLeft: "auto",
              marginRight: 0,
            }}>
            <GpjButton
              text={t(`missingRights_${transitionName}`)}
              enabledBackground={FlowUiType2Color(transitionDefinition.meta?.flowUi)}
              isDisabled={true}
            />
          </Div>
        } else if (transitionDefinition.meta?.requiresComment) {
          return <Div key={transitionName}
            style={{
              marginLeft: "auto",
              marginRight: 0,
            }}>
            <GpjButton
              text={t(`btnTxt_${transitionName}`)}
              enabledBackground={FlowUiType2Color(transitionDefinition.meta?.flowUi)}
              onPress={() => setTransitionOptions([transitionName])}
              isDisabled={akte.performTransitionLoading || akte.performTransition == undefined || uebergabeBlocked}
            />
          </Div>
        } else {
          return <Div key={transitionName}
            style={{
              marginLeft: "auto",
              marginRight: 0,
            }}>
            <GpjButton
              text={t(`btnTxt_${transitionName}`)}
              enabledBackground={FlowUiType2Color(transitionDefinition.meta?.flowUi)}
              onPress={
                akte.performTransition == undefined
                  ? undefined
                  : () => {
                    closeDialog()
                    akte.performTransition!(transitionName)
                  }
              }
              isDisabled={akte.performTransitionLoading || akte.performTransition == undefined || uebergabeBlocked}
            />
          </Div>
        }
      })

    const revokeTransitions = nonDecissionTransitions
      .filter(availableTransition => availableTransition[1].meta?.flowUi == "revoke")
      .filter(availableTransition => null!= akte.stateHistory?.find(entry => entry.state == availableTransition[1].to))

    if (revokeTransitions.length > 0) {
      const hasPermissions = revokeTransitions[0][1].meta?.allowedRoles?.some(
        allowedRole => satisfiesRoleSpecifier(authCtx?.roles, allowedRole)
      )

      const transitionName = revokeTransitions[0][0]
      if (!hasPermissions) {
        return [
          ...nonRevokeActions,
          <Div key={transitionName}
            style={{
              marginLeft: "auto",
              marginRight: 0,
            }}>
            <Text
              style={{
                fontSize: 18,
              }}
            >{t(`missingRights_returnFrom_${uebergabeStep}`)}</Text>
          </Div>,
        ]
      } else {

        const revokeTransitionNames = revokeTransitions.map(([transitionName, _]) => transitionName).reverse()
        return [
          ...nonRevokeActions,
          <Div key={transitionName}
            style={{
              marginLeft: "auto",
              marginRight: 0,
            }}>
            <GpjButton
              text={t(`btnTxt_returnFrom_${uebergabeStep}`)}
              enabledBackground={FlowUiType2Color("revoke")}
              onPress={() => setTransitionOptions(revokeTransitionNames)}
              isDisabled={akte.performTransitionLoading || akte.performTransition == undefined}
            />
          </Div>,
        ]
      }
    } else {
      return nonRevokeActions
    }
  }, [
    akte.availableTransitions,
    akte.performTransition,
    akte.performTransitionLoading,
    akte.processUebergabeStep,
    authCtx?.roles,
    closeDialog,
    t,
    uebergabeBlocked,
    uebergabeStep,
  ])

  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <H3 style={{
          wordWrap: "break-word",
          color: dsv.colors.connectGrau7,
          marginVertical: 0,
          paddingVertical: 0,
        }}>
          {t(`uebergabeTitle_${uebergabeStep}`)}: {akte.serverState?.UE_Kundendaten_Projekttitel}
        </H3>
        <View style={{ margin: 5 }}>
          {uebergabeBlocked
            ? <Text style={{ fontSize: 16 }} >Fehlende Pflichtfelder</Text>
            : buttons
          }
        </View>
      </View>
      {transitionOptions && (<TransitionCommentDialog closeDialoge={closeDialog} transitionOptions={transitionOptions} />)}
    </View>
  )
}
