import { SelectProps } from '.'
import { InputRadioList } from './InputRadioList'
import { InputSelect } from './InputSelect'

export function InputEnum(props: SelectProps) {
  if (
    props.options?.length == 3 &&
    props.options.includes('') &&
    props.options.includes('Ja') &&
    props.options.includes('Nein')
  ) {
    return InputRadioList({
      ...props,
      optionsWithKey: [
        { key: 'Ja', value: 'Ja' },
        { key: 'Nein', value: 'Nein' },
        { key: '', value: 'offen' },
      ],
    }) // JaNeinEnum should be rendered as two radioButtons, well no longer but i would like to keep the option in case of requirement changes ...
  }

  return InputSelect(props)
}
