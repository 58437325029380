import { Div } from '@expo/html-elements'

export interface UebergabeSpacerRowProps {
  size: 'small' | 'normal' | 'large'
}

export function UebergabeSpacerRow({ size }: UebergabeSpacerRowProps) {
  return (
    <Div
      // TODO: Fix styling for mobile app
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ height: size == 'small' ? '0.5em' : size == 'normal' ? '1em' : '1.5em' }}
    ></Div>
  )
}
