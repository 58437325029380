import { z } from 'zod'
import { NoExtraProperties, NonPartial } from '../utils/typing'
import { OnlyValideLabels } from '../locales/translationKeys'

import { AkteSchema } from '@dpa/common/dist'
import { SchemaToFormSchema } from '../components/form/Autoform'

export const ProjektAnlageFormFields = {
  UE_Kundendaten_Projekttitel: true,
} as const

const ProjektAnlageSchema = AkteSchema.pick(ProjektAnlageFormFields)
export const ProjektAnlageForm = SchemaToFormSchema(ProjektAnlageSchema, {})
type _ProjektAnlageFormType = NonPartial<z.infer<typeof ProjektAnlageForm>>
type _HasLabels_Uebergabe_KundendatenForm = NoExtraProperties<
  _ProjektAnlageFormType,
  Pick<_ProjektAnlageFormType, OnlyValideLabels<keyof _ProjektAnlageFormType>>
>
