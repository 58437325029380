import { NoDataPlaceholder } from '../../../../../constants'

export function formatAkzeptiertAm(
  requested: Date | undefined | null,
  rejected: Date | undefined | null,
  approved: Date | undefined | null
) {
  return requested == undefined
    ? rejected == undefined
      ? NoDataPlaceholder
      : `Abgelehnt am ${rejected?.toLocaleDateString()}`
    : approved?.toLocaleDateString() ?? 'Ausstehend'
}

export function formatReason(doNotDisplayIfDefined: unknown | undefined | null, text: string | undefined | null) {
  return doNotDisplayIfDefined != undefined ? NoDataPlaceholder : text ?? NoDataPlaceholder
}

export function formatDate(date: Date | undefined | null) {
  return date?.toLocaleDateString() ?? NoDataPlaceholder
}

export function formatDateTime(date: Date | undefined | null) {
  return date == undefined ? NoDataPlaceholder : `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}
