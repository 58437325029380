import { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'

export const mitarbeiterDropdownFields: { [name: string]: undefined | Array<RoleEnumType> } = {
  PDB_VertriebsmitarbeiterId: ['Vertrieb'],
  PDB_InnendienstmitarbeiterId: ['Innendienst'],
  PDB_ProjektiererId: ['Projektierung'],
  PDB_Projektleiter_PMOId: ['PMO'],
  //PDB_Ansprechpartner_ProjektsupportId: ['ProjektSupport'],
  PDB_Ansprechpartner_TBFId: ['ServiceUndWartung'],
}
