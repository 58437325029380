import { Div } from '@expo/html-elements'

import { useAkteContext } from '../../../../../hooks/context/useAkteContext'

import { AkteStateEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/AkteStateEnumSchema'
import { H4 } from '../../../../../components/TextComponents'
import { useTranslation } from 'react-i18next'
import { TransitionLog } from './TransitionLog'
import { Collapsible } from '../../../../../components/Collapsible'

export interface HistoryEntryProps {
  transitionName: string
  state: AkteStateEnumType
  at: Date
  comment?: string | undefined | null
}

export function SubCardTransitionHistory() {
  const akte = useAkteContext()
  const isLoading = akte.isLoading

  const { t } = useTranslation()
  if (isLoading || akte.stateHistory.length == 0) return undefined

  const stateText = t('stateHistoryText_' + akte.stateHistory[0].state)
  return (
    <Collapsible
      defaultOpen={true}
      summary={
        <Div>

          <H4
            style={{
              fontSize: 20,
              marginLeft: 8,
              marginHorizontal: 10,
              marginVertical: 10,
            }}
          >
            Übergabenhistorie
          </H4>
        </Div>
      }
    >
      <H4>Aktueller Status: {stateText}</H4>
      <TransitionLog />
    </Collapsible>
  )
}
