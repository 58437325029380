/* eslint max-len: 0 */ // --> OFF

import { Div } from '@expo/html-elements'

import { StateHistoryEntry, useAkteContext } from '../../../../../hooks/context/useAkteContext'

import { useAllPermissions } from '../../../../../hooks/useAllPermissions'
import { useProcessGateContext } from '../../../../../hooks/context/useProcessGateContext'

import { useAkteSalesLines } from '../../../../../hooks/useAkteSalesLines'
import { SubCard } from '../../../../../components/SubCard'

import { UebergabeRow } from './UebergabeRow'
import { UebergabeHeaderRow } from './UebergabeHeaderRow'
import { UebergabeSpacerRow } from './UebergabeSpacerRow'
import { formatAkzeptiertAm, formatDate, formatReason } from './utils'
import { useRelevantHandovers } from '../../hooks/useRelevantHandovers'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'
import { useMemo } from 'react'

export type HandoverEventData = { 
  [processType in ProcessUebergabeStepType]: { 
    preRequest: undefined | null | StateHistoryEntry,
    preRequestRejected: undefined | null | StateHistoryEntry,
    request: undefined | StateHistoryEntry,
    approved: undefined | StateHistoryEntry,
    rejected: undefined | StateHistoryEntry,
  } 
}

export function SubCardUebergaben() {
  const akte = useAkteContext()

  const salesLines = useAkteSalesLines(akte.identifier)

  const allPermissions = useAllPermissions()

  const processGateCtx = useProcessGateContext()
  
  const relevantHandovers = useRelevantHandovers()

  const isLoading = akte.isLoading || allPermissions.isLoading || processGateCtx.isLoading || salesLines.isLoading

  const allHandovers = [
    "Vertrieb2PE",
    "PE2Vertrieb",
    "Vertrieb2ENG",
    "ENG2Vertrieb",
    "Vertrieb2Projektleitung",
    "Projektleitung2SuW",
  ] as const
  
  const allActions = [
    "preRequest",
    "preRequestRejected",
    "request",
    "approved",
    "rejected",
    "returnFrom",
  ] as const


  const handoverData: HandoverEventData  = (() => {
    const tmpRes = {
      Vertrieb2PE: {
        preRequest: null,
        preRequestRejected: null,
        request: undefined,
        approved: undefined,
        rejected: undefined,
        returnFrom: undefined,
      },
      PE2Vertrieb: {
        preRequest: undefined,
        preRequestRejected: undefined,
        request: undefined,
        approved: undefined,
        rejected: undefined,
        returnFrom: undefined,
      },
      Vertrieb2ENG: {
        preRequest: null,
        preRequestRejected: null,
        request: undefined,
        approved: undefined,
        rejected: undefined,
        returnFrom: undefined,
      },
      ENG2Vertrieb: {
        preRequest: null,
        preRequestRejected: null,
        request: undefined,
        approved: undefined,
        rejected: undefined,
        returnFrom: undefined,
      },
      Vertrieb2Projektleitung: {
        preRequest: null,
        preRequestRejected: null,
        request: undefined,
        approved: undefined,
        rejected: undefined,
        returnFrom: undefined,
      },
      Projektleitung2SuW: {
        preRequest: null,
        preRequestRejected: null,
        request: undefined,
        approved: undefined,
        rejected: undefined,
        returnFrom: undefined,
      },
    }
    let newestHandover: number = allHandovers.length
    if(akte.stateHistory){
      for(const state of akte.stateHistory){
        const tmp = state.transitionName.split("_")
        if(tmp.length == 2){  
          // if the splitted transition name contains 2 elements it is an handover action 
          // for example 'Vertrieb2PE_request' => ['Vertrieb2PE', 'request']
          const [handoverStep, handoverAction] = tmp
          const handoverIdx = allHandovers.indexOf(handoverStep)
          if(handoverIdx >= 0 && handoverIdx <= newestHandover){
            if(allActions.includes(handoverAction) && tmpRes[handoverStep] != undefined && tmpRes[handoverStep][handoverAction] === undefined){
              tmpRes[handoverStep][handoverAction] = state
              newestHandover = handoverIdx
            }
          }
        }else if(tmp.length == 4){
          // if the splitted transition name contains 4 elements it is an return to handover action 
          // for example 'returnFrom_SuW_TO_Vertrieb2PE' => ['returnFrom', 'SuW', 'TO', 'Vertrieb2PE']
          if(tmp[0] == "returnFrom"){
            const handoverAction = tmp[0]
            const handoverStep = tmp[3]
            const handoverIdx = allHandovers.indexOf(handoverStep)
            if(handoverIdx >= 0 && handoverIdx <= newestHandover){
              if(allActions.includes(handoverAction) && tmpRes[handoverStep] != undefined && tmpRes[handoverStep][handoverAction] === undefined){
                tmpRes[handoverStep][handoverAction] = state
                newestHandover = handoverIdx
              }
            }
          }
        }
      }
    }
    return tmpRes
  })()

  if(handoverData == undefined) return <></>
  return (
    <>
      <SubCard title="Übergaben">
        <Div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <UebergabeHeaderRow
            headerCols={relevantHandovers}
          />
          <UebergabeSpacerRow size="small" />
          <UebergabeRow
            handovers = {relevantHandovers}
            step = "request"
            data={handoverData}
          />
          <UebergabeRow
            handovers = {relevantHandovers}
            step = "preRequest"
            data={handoverData}
          />
          <UebergabeRow
            handovers = {relevantHandovers}
            step = "preRequestRejected"
            data={handoverData}
          />
          <UebergabeRow
            handovers = {relevantHandovers}
            step = "approved"
            data={handoverData}
          />
          <UebergabeRow
            handovers = {relevantHandovers}
            step = "rejected"
            data={handoverData}
          />
          <UebergabeRow
            handovers = {relevantHandovers}
            step = "returnFrom"
            data={handoverData}
          />
          <UebergabeSpacerRow size="normal" />
        </Div>
      </SubCard>
    </>
  )
}
