import { CalcAkte } from '@dpa/common/dist'
import { useSyncAkteWithBCMutation } from './mutations/useSyncAkteWithBCMutation'
import { useCallback, useState } from 'react'
import { SyncError } from '@dpa/server/integations/bc/bcSyncers'
import { trpc } from '../../../App'

export function useSyncAkteWithBC(akte: Partial<CalcAkte> | undefined) {
  const syncBCMutation = useSyncAkteWithBCMutation()
  const trpcCtx = trpc.useUtils()
  const canSyncBC = akte?.id != undefined && akte?.PDB_Auftragsnummer != undefined
  const [errors, setErrors] = useState<Array<SyncError>>([])
  const syncBCFunction = useCallback(() => {
    if (akte != undefined && canSyncBC) {
      syncBCMutation.mutate(
        {
          id: akte.id!,
        },
        {
          onSuccess: (result) => {
            trpcCtx.akte.salesLines.invalidate()
            if (result.errors.length > 0) {
              console.log('errors', result.errors)
              setErrors(result.errors)
            } else {
              if (errors.length > 0) {
                setErrors([])
              }
            }
          },
        }
      )
      return true
    }
    return undefined
  }, [akte, canSyncBC, syncBCMutation, trpcCtx.akte.salesLines, errors.length])

  return { canSyncBC, syncBCFunction, syncBCMutation, errors }
}
