import { TBody, Table, THead, TR, Div } from '@expo/html-elements'
import { H4, TD, Text, TH } from '../../../../components/TextComponents'

import { dsv } from '../../../../styles/defaults'

import { SubCard } from '../../../../components/SubCard'
import { toCurrencyString } from '../../../../utils/currency'
import { useMemo } from 'react'
import type { ServiceContractLines } from '@dpa/server/integations/bc/ServiceContractLines'

interface ServiceCardProps {
  title: string
  sericeLines: ServiceContractLines
}

export function ServiceCard({ title, sericeLines }: ServiceCardProps) {

  const summs = useMemo(
    () => {
      return sericeLines.reduce(
        (carry, line) => {
          carry.betrag = carry.betrag + (line.lineamount ?? 0)
          return carry
        }, {
          betrag:0, 
        } as {
          betrag:number
        }
      )
    }, 
    [sericeLines]
  )
  const summ_MwSt=summs.betrag*0.19
  const summWithMwSt=summs.betrag+summ_MwSt

  return (
    <SubCard width="100%" title={title}>
      <Table
        style={{
          // TODO: Fix styling for mobile app
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          borderSpacing: '0px 6px',
        }}
      >
        <THead style={{ backgroundColor: dsv.colors.GpJouleGelb }}>
          <TR>
            <TH style={{ padding: 10, textAlign: 'left' }}>Artikelnummer</TH>
            <TH style={{ padding: 10, textAlign: 'left' }}>Beschreibung</TH>
            <TH style={{ padding: 10, textAlign: 'center' }}>Menge</TH>
            <TH style={{ padding: 10, textAlign: 'center' }}>Einzelpreis netto</TH>
            <TH style={{ padding: 10, textAlign: 'center' }}>Gesamtpreis netto</TH>
          </TR>
        </THead>
        <TBody>
          {sericeLines.map((line) => (
            <>
              <TR key={`${line.lineno_}_spcaer`} >
                <TD style={{ height: 5 }}></TD>
              </TR>
              <TR key={line.lineno_}>
                <TD style={{ paddingLeft: 10 }}>{line.itemno_}</TD>
                <TD>{line.description}</TD>
                <TD style={{ textAlign: 'center' }}>
                  {line.inwitemquantity == 0 ? (
                    ''
                  ) : (
                    <>
                      {line.inwitemquantity}&nbsp;{line.unitofmeasurecode}
                    </>
                  )}
                </TD>
                <TD style={{ textAlign: 'right', paddingRight: 10 }}>
                  {line.inwitemquantity == 0 ? '' : toCurrencyString(line.inwunitprice ?? 0)}
                </TD>
                <TD style={{ textAlign: 'right', paddingRight: 10 }}>
                  {line.inwitemquantity == 0 ? '' : toCurrencyString(line.lineamount ?? 0)}
                </TD>
              </TR>
            </>
          ))}
          <TR>
            <TD style={{ height: 5 }}></TD>
          </TR>
        </TBody>
      </Table>
      <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
      <Div style={{ marginLeft: 'auto' }}>
        <Table style={{ borderSpacing: '10px 5px' }}>
          <TR>
            <TD>
              <Text>Nettobetrag</Text>
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <Text>
                {toCurrencyString(summs.betrag)}
              </Text>
            </TD>
          </TR>
          <TR>
            <TD></TD>
            <TD style={{height: 1, backgroundColor: dsv.colors.connectGrau }}></TD>
          </TR>
          <TR>
            <TD>
              <Text style={{ marginTop: 5 }}>MwSt.-Betrag 19 %</Text> 
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <Text>
                {toCurrencyString(summ_MwSt)}
              </Text>
            </TD>
          </TR>
          <TR style={{}}>
            <TD>
              <H4 style={{ marginTop: 5 }}>Gesamt</H4>
            </TD>
            <TD style={{ textAlign: 'right' }}>
              <H4>
                {toCurrencyString(summWithMwSt)}
              </H4>
            </TD>
          </TR>
        </Table>
        <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
        <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
      </Div>
    </SubCard>
  )
}
