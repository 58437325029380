import {
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { RoleSchema } from '@dpa/server/trpc/routers/permissions'
import { z } from 'zod'
import { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'

export type Role = z.infer<typeof RoleSchema>

interface AuthData {
  id: number
  name: string
  email: string
  roles: Array<RoleEnumType>
  accessToken: string
  refreshToken: string
}

export interface AuthCtx {
  authCtx: AuthData | null
  setAuthCtx: Dispatch<SetStateAction<AuthData | null>>
  clearAuthCtx: () => void
  getAccessToken: () => Promise<string | null>
  hasRole: (role: RoleEnumType) => boolean
}

const AuthContext = createContext<AuthCtx>({
  authCtx: null,
  setAuthCtx: () => {},
  clearAuthCtx: () => {},
  getAccessToken: () => new Promise(() => null),
  hasRole: (_: RoleEnumType) => {
    return true
  },
})

export interface AuthContextProviderProps {}

function decodeJwtToken(jwt: string) {
  const splittedJwt = jwt.split('.')
  if (splittedJwt.length !== 3) {
    return null
  }

  return JSON.parse(atob(splittedJwt[1]))
}

export function AuthContextProvider({ children }: PropsWithChildren<AuthContextProviderProps>) {
  const [authCtx, setAuthCtx] = useState<AuthData | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    const authCtxStrFromLocalStorage = localStorage.getItem('auth-ctx')
    if (authCtxStrFromLocalStorage != null && authCtxStrFromLocalStorage.length > 0) {
      const authCtxFromLocalStorage = JSON.parse(authCtxStrFromLocalStorage)
      if (authCtxFromLocalStorage != null) {
        setAuthCtx(authCtxFromLocalStorage)
        if (location.pathname === '/login') {
          navigate('/')
        }
      }
    } else if (location.pathname !== '/login') {
      navigate('/login')
    }
  }, [navigate])

  useEffect(() => {
    if (authCtx?.accessToken != null && authCtx.accessToken.length > 0) {
      localStorage.setItem('auth-ctx', JSON.stringify(authCtx))
    }
  }, [authCtx])

  const clearAuthCtx = useCallback(() => {
    setAuthCtx(null)
    localStorage.clear()
    navigate('/login')
  }, [navigate])

  const getAccessToken = useCallback(async () => {
    if (authCtx?.accessToken) {
      const decodedToken = decodeJwtToken(authCtx?.accessToken)
      if (decodedToken['exp'] - 60 < Date.now() / 1000) {
        const decodedRefreshToken = decodeJwtToken(authCtx?.refreshToken)
        if (decodedRefreshToken['exp'] < Date.now() / 1000 - 60) {
          return null
        }
        return fetch('/api/rest/refresh', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refreshToken: authCtx?.refreshToken }),
        }).then((resp) => {
          if (resp.ok) {
            return resp
              .json()
              .then(
                (jd: {
                  id: number
                  email: string
                  name: string
                  roles: string[]
                  refreshToken: string
                  accessToken: string
                }) => {
                  setAuthCtx({
                    accessToken: jd.accessToken,
                    email: jd.email,
                    id: jd.id,
                    name: jd.name,
                    refreshToken: jd.refreshToken,
                    roles: jd.roles as unknown as Role[],
                  })
                  return jd.accessToken
                }
              )
          }
          return null
        })
      }

      return authCtx?.accessToken
    }
    return null
  }, [authCtx])

  const hasRole = useCallback(
    (role: RoleEnumType) => {
      for (const r of authCtx?.roles ?? []) {
        if (r === role) {
          return true
        }
      }
      return false
    },
    [authCtx]
  )

  return (
    <AuthContext.Provider
      value={{
        authCtx,
        setAuthCtx,
        clearAuthCtx,
        getAccessToken,
        hasRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  const ctx = useContext(AuthContext)
  if (ctx == null) {
    throw new Error('usage of useAuthContext outside of a AuthContext') // TODO error Boundary
    //TODO: maybe handle another way
  }
  return ctx
}
