import { Div } from '@expo/html-elements'
import { Strong } from '../../TextComponents'
import { dsv } from '../../../styles/defaults'
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { addPrefix } from '../../../locales/translationKeys'


import SelectList from '../../SelectList'
import { DataListFiltersEntry } from './DataListFiltersEntry'
import { DataListData } from './dataAndTypes'

export type DataListFiltersProps<
  TData extends DataListData<TKeys>,
  TKeys extends string
> = {
  data: undefined | Array<TData>,
  allFilterOptions: Array<TKeys>,
  activeFilters: { [attribute in TKeys]?: { active: boolean, filter: string | undefined } },
  setActiveFilters: Dispatch<SetStateAction<{ [attribute in TKeys]?: { active: boolean, filter: string | undefined } }>>
}

export function DataListFilters<
  TData extends DataListData<TKeys>,
  TKeys extends string
>({
  data,
  allFilterOptions,
  activeFilters,
  setActiveFilters
}: DataListFiltersProps<TData, TKeys>) {

  const { t } = useTranslation()

  const filterEntries = useMemo(() => Object.entries(activeFilters) as Array<[TKeys, { active: boolean, filter: string | undefined }]>, [activeFilters])

  const activateFieldFilter = useCallback(
    (field: TKeys) => setActiveFilters(
      (old) => ({ ...old, [field]: { active: true, filter: old[field]?.filter } })
    ),
    [allFilterOptions, setActiveFilters]
  )
  const deactivateFiledFilters = useMemo(
    () => Object.fromEntries(
      allFilterOptions.map(
        field => (
          [field, () => setActiveFilters((old) => ({ ...old, [field]: { active: false, filter: old[field]?.filter } }))]
        )
      )
    ),
    [allFilterOptions, setActiveFilters]
  )
  const setFieldFilter = useMemo(
    () => Object.fromEntries(
      allFilterOptions.map(
        option => (
          [option, (filter: string | undefined) => setActiveFilters((old) => ({ ...old, [option]: { active: old[option]?.active ?? false, filter } }))]
        )
      )
    ),
    [allFilterOptions, setActiveFilters]
  )

  const filterOptions = useMemo(
    () => allFilterOptions
      .filter((option) => activeFilters[option]?.active != true)
      .map((field) => ({ key: field, value: t(addPrefix('tableHeaders', field)) }))
    , [allFilterOptions, activeFilters]
  )


  return (
    <Div style={{
      marginRight: 5,
      paddingHorizontal: 5,
      paddingBottom: 5,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      backgroundColor: dsv.colors.GpJouleGelb,
      zIndex: 98,
    }}>
      <Strong style={{ fontSize: 18 }}>Projekte filtern:</Strong>
      <SelectList

        disabled={false}
        data={filterOptions}
        setSelected={activateFieldFilter}
        dropdownStyles={{ position: 'absolute', zIndex: 98, marginTop: 33 }}
        boxStyles={{
          height: 28,
          width: '100%',
          backgroundColor: dsv.colors.connectGrau7,
          borderRadius: 0,
          borderStyle: 'solid',
          borderColor: dsv.colors.connectGrau30,

          position: 'relative',
          zIndex: 98,
        }}
        inputStyles={{
          height: 28,
          width: '100%',
          backgroundColor: dsv.colors.connectGrau7,
          borderRadius: 0,
          borderStyle: 'solid',
          borderColor: dsv.colors.connectGrau30,
          marginLeft: -15
        }}
        dropdownItemStyles={{ position: 'relative', zIndex: 98 }}
        dropdownTextStyles={{ position: 'relative', zIndex: 98 }}
        disabledItemStyles={{}}
        disabledTextStyles={{}}
        placeholder="Feld Hinzufügen"
        notFoundText="Keine Weiteren Felder"
      />
      <Div>
        {filterEntries.map(
          ([field, filter]) => {
            return (
              <DataListFiltersEntry
                key={field}
                deactivateThisFilter={deactivateFiledFilters[field]}
                setThisFieldFilter={setFieldFilter[field]}
                field={field}
                active={filter.active}
                filter={filter.filter}
              />
            )
          }
        )}
      </Div>
    </Div>
  )
}
