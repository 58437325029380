import { Div } from '@expo/html-elements'
import { Text } from '../../../../components/TextComponents'
import { ActivityIndicator, ColorValue, DimensionValue } from 'react-native'
import { dsv } from '../../../../styles/defaults'

interface GpjButtonProps {
  text: string
  onPress?: () => void | undefined
  enabledBackground?: ColorValue
  isDisabled?: boolean
  icon?: JSX.Element
  isLoading?: boolean
  width?: DimensionValue
  height?: DimensionValue
}
export function GpjButton({
  text,
  onPress,
  enabledBackground = dsv.colors.Kontra,
  isDisabled,
  icon,
  isLoading,
  width,
  height,
}: GpjButtonProps) {
  return (
    <Div
      style={{
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        border: 2,
        width: width ?? 250,
        height: height ?? 36,
        borderStyle: 'solid',
        paddingHorizontal: 16,
        paddingVertical: 4,
        borderColor: dsv.colors.connectGrau,
        backgroundColor: isDisabled ? dsv.colors.connectGrau30 : enabledBackground,
        marginBottom: 4,
        marginTop: 4,
        borderRadius: 8,
        marginHorizontal: 10,
        cursor: "pointer",
      }}

      onPointerDown={isDisabled ? undefined : onPress}
    >
      <Text
        style={{
          fontSize: 14,
          margin: 'auto',
          textAlign: 'right',
          color: dsv.colors.connectGrau,
        }}
      >
        {icon}
        {text}
        {isLoading && <ActivityIndicator style={{}} size={14} color={dsv.colors.connectGrau} />}
      </Text>
    </Div>
  )
}
