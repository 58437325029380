import { View, ScrollView } from 'react-native'
import { Card, CardBody, CardHeader } from '../../components/Card'
import { ProjectList } from '../../components/ProjectList'
import { useAktePages } from '../../hooks/useAktePages'
import { config } from '../../config'


export function ProjectsHome() {
  const projects = useAktePages(config.pageSize)
  return (
    <View>
      <Card customStyle={{ margin: 10, width: 1576 }}>
        <CardHeader>Alle Projekte</CardHeader>
        <CardBody>
          <ScrollView style={{ maxHeight: '100%' }}>
            <ProjectList 
              projects={projects.data} 
              isLoading={projects.isLoading}
              loadMore={projects.loadMore}
              dataItemCount={projects.itemCount}
              noDataPlaceholder="Keine Projekte"
            />
          </ScrollView>
        </CardBody>
      </Card>
    </View>
  )
}
