import { ChangeEvent, KeyboardEvent, useMemo, useRef } from 'react'
import { InputProps } from '.'
import { dsv } from '../../../styles/defaults'

// TODO: firefox hotfix see https://bugzilla.mozilla.org/show_bug.cgi?id=1398528
function preventNonNumericalInput(event: KeyboardEvent<HTMLInputElement>) {
  if ( event.code != "Backspace" &&  event.code != "Delete" && !event.code.startsWith("Arrow") && !event.key.match(/^[0-9\.]+$/)){
    event.preventDefault();
  }
}

export function InputNumber({ fieldId, strValue, setStrValue, onBlur, style }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange = useMemo(() => {
    if (setStrValue) {
      return (event: ChangeEvent<HTMLInputElement>) => {
        inputRef.current?.focus() // This is needed, because (in firefox) the up and down buttons do not focus
        if(Number.isFinite(Number(event.target.value))){
          setStrValue(event.target.value)
        }
      }
    } else {
      return undefined
    }
  }, [setStrValue, inputRef])

    return (
    <input
      type="number"
      ref={inputRef}
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      onChange={onChange}
      style={{ height: 24, ...dsv.text, ...style }}
      onBlur={onBlur}
      onKeyDownCapture={preventNonNumericalInput}
    />
  )
}
