import { Div } from '@expo/html-elements'
import { dsv } from '../../../styles/defaults'
import SelectList from '../../SelectList'
import { t } from 'i18next'
import { addPrefix } from '../../../locales/translationKeys'
import { useMemo } from 'react'

export type DataListAddFieldProps<TOptions extends string> = {
  height: number
  padding: number
  fieldOptions: Array<TOptions>
  addField: (option: TOptions) => void
}

export function DataListAddField<TOptions extends string>({
  height,
  padding,
  fieldOptions,
  addField,
}:DataListAddFieldProps<TOptions>){
  const data = useMemo(
    ()=>fieldOptions.map((field) => ({key:field, value: t(addPrefix('tableHeaders', field))})),
    [fieldOptions]
  )
  return (
    <Div style={{
      height: height,
      backgroundColor: dsv.colors.GpJouleGelb,
      justifyContent:"center",
      padding: padding,
      zIndex: 99, 
    }}>
      <SelectList

        disabled={false}
        data={data}
        setSelected={addField}
        
        dropdownStyles={{ position: 'absolute', zIndex: 99, marginTop: 33 }}
        boxStyles={{
          height: 28,
          width: '100%',
          backgroundColor: dsv.colors.connectGrau7,
          borderRadius: 0,
          borderStyle: 'solid',
          borderColor: dsv.colors.connectGrau30,

          position: 'relative', 
          zIndex: 99, 
        }}
        inputStyles={{
          height: 28,
          width: '100%',
          backgroundColor: dsv.colors.connectGrau7,
          borderRadius: 0,
          borderStyle: 'solid',
          borderColor: dsv.colors.connectGrau30,

          marginLeft:-15
        }}
        dropdownItemStyles={{ position: 'relative', zIndex: 99}}
        dropdownTextStyles={{ position: 'relative', zIndex: 99}}
        disabledItemStyles={{}}
        disabledTextStyles={{}}
        placeholder="Feld Hinzufügen"
        notFoundText="Keine Weiteren Felder"
        
      />
    </Div>
  )
}