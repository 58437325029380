import { useMemo } from 'react'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'

export function useRelevantHandovers() {
  const akte = useAkteContext()

  const relevantHandovers = useMemo(() => {
    const relevantHandovers: Array<ProcessUebergabeStepType> = []
    const workonStates = akte.stateHistory?.filter(({ state }) => state.startsWith("workOn_")).map(({ state }) => state)

    if (workonStates?.includes("workOn_Vertrieb2PE")) {
      relevantHandovers.push("Vertrieb2PE")
    }
    if (workonStates?.includes("workOn_PE2Vertrieb")) {
      relevantHandovers.push("PE2Vertrieb")
    }
    if (workonStates?.includes("workOn_Vertrieb2ENG")) {
      relevantHandovers.push("Vertrieb2ENG")
    }
    if (workonStates?.includes("workOn_ENG2Vertrieb")) {
      relevantHandovers.push("ENG2Vertrieb")
    }
    if (workonStates?.includes("workOn_Vertrieb2Projektleitung")) {
      relevantHandovers.push("Vertrieb2Projektleitung")
    }
    if (workonStates?.includes("workOn_Projektleitung2SuW")) {
      relevantHandovers.push("Projektleitung2SuW")
    }
    return relevantHandovers
  }, [akte.stateHistory])

  return relevantHandovers
}
