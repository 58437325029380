import { useMemo } from 'react'
import { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'
import { RoleSpecifier } from '@dpa/common/dist'
import { useRelevantHandovers } from '../../pages/pdb/projects/hooks/useRelevantHandovers'


type UebergabenVisibilitySpecification = {
  [step in ProcessUebergabeStepType]: Array<RoleSpecifier>
}

const uebergabenVisibilitySpecification: UebergabenVisibilitySpecification = {
  Vertrieb2PE: [
    { Admin: true },
    { Innendienst: true },
    { Vertrieb: true },
    { PE: true },
  ],
  PE2Vertrieb: [
    { Admin: true },
    { Innendienst: true },
    { Vertrieb: true },
    { PE: true },
  ],
  Vertrieb2ENG: [
    { Admin: true },
    { Innendienst: true },
    { Vertrieb: true },
    { Projektierung: true },
  ],
  ENG2Vertrieb: [
    { Admin: true },
    { Innendienst: true },
    { Vertrieb: true },
    { Projektierung: true },
  ],
  Vertrieb2Projektleitung: [
    { Admin: true },
    { Innendienst: true },
    { Vertrieb: true },
    { PMO: true },
    { ProjektSupport: true },
  ],
  Projektleitung2SuW: [
    { Admin: true },
    { PMO: true },
    { ServiceUndWartung: true },
    { ProjektSupport: true },
  ],
  SuW2Running: [
    { Admin: true },
    { ServiceUndWartung: true },
  ],
}

export function satisfiesRoleSpecifier(roles: Array<RoleEnumType> | undefined, roleSpecifier: RoleSpecifier) {
  if (roles == undefined) return false
  return Object.keys(roleSpecifier).every((role) => roles.includes(role as RoleEnumType))
}

export function useUebergabenToDisplay(roles: undefined | Array<RoleEnumType>): Array<ProcessUebergabeStepType> {
  const relevantHandovers = useRelevantHandovers()
  return useMemo(
    () => {
      const uebergabenToDisplay = relevantHandovers.filter(
        handover => uebergabenVisibilitySpecification[handover].some(
          roleSpecifiert => satisfiesRoleSpecifier(roles, roleSpecifiert)
        )
      )
      return uebergabenToDisplay
    },
    [relevantHandovers, roles]
  )
}
